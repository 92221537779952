import { useNavigate } from "react-router-dom";
import toast from "react-hot-toast";
import api from "../../../services/api";
import RichTextEditor from "../../../components/RichTextEditor";

export default ({ feature, setFeature }) => {
  const navigate = useNavigate();

  async function onDelete() {
    const confirm = window.confirm("Are you sure ?");
    if (!confirm) return;
    await api.remove(`/feature/${feature._id}`);
    toast.success("successfully removed!");
    navigate("/features");
  }

  async function handleSubmit() {
    try {
      const { ok, data, code } = await api.put(`/feature/${feature._id}`, feature);
      if (!ok) return toast.error(`Error !\n${code}`, { icon: "❌" });
      setFeature(data);
      toast.success("Updated !");
    } catch (e) {
      console.log(e);
      toast.error("Some Error!", e.code);
    }
  }

  if (!feature) return <div />;

  return (
    <div className="space-y-8">
      <div className="grid grid-cols-2 gap-4 p-2">
        <div className="w-full space-y-2">
          <label className="font-semibold">Title</label>
          <input type="text" className="input" value={feature.title} onChange={(e) => setFeature({ ...feature, title: e.target.value })} />
        </div>
        <div className="col-span-2 w-full space-y-2">
          <label className="font-semibold">Description</label>
          <RichTextEditor
            buttonList={[
              ["font", "fontSize", "formatBlock"],
              ["bold", "underline", "italic"],
              ["fontColor", "hiliteColor"],
              ["align", "list", "lineHeight"],
              ["outdent", "indent"],
              ["link"],
              ["removeFormat"],
            ]}
            values={feature.description}
            options={{ minHeight: "150px", backGorundColor: "white" }}
            onChange={(e) => setFeature({ ...feature, description: e })}
          />
        </div>
      </div>
      <div className="flex gap-4 justify-end">
        <button className="red-btn" onClick={onDelete}>
          Delete
        </button>
        <button className="blue-btn" onClick={handleSubmit}>
          Update
        </button>
      </div>
    </div>
  );
};
