import React, { useEffect, useState } from "react";
import { useParams, useNavigate, useLocation } from "react-router-dom";
import toast from "react-hot-toast";
import { IoMdClose } from "react-icons/io";
import { ResponsiveRadar } from "@nivo/radar";

import Loader from "../../../components/loader";
import api from "../../../services/api";
import Modal from "../../../components/modal";
import SelectUser from "../../../components/selectUser";

export default function EditProject() {
  const [project, setProject] = useState(null);
  const [members, setMembers] = useState([]);

  const { id } = useParams();

  useEffect(() => {
    fetchData();
  }, []);

  async function fetchData() {
    const { data: u } = await api.get(`/project/${id}`);
    setProject(u);
    const ids = u.team.map((member) => member.userId);
    const { data } = await api.post(`/user/search`, { ids });
    setMembers(data.users);
  }

  if (!project) return <Loader />;

  return (
    <div className="">
      <div className="flex items-center gap-2 mb-2">
        <div className="font-semibold text-sm tracking-wider">Members</div>
        <span className="bg-sky-50 border border-sky-100 text-sky-600 px-5 py-1 rounded-full">{members.filter((member) => member.role !== "client")?.length}</span>
        <div className="flex items-center gap-4 justify-between">
          <SelectUser
            userRoles={["admin", "normal", "client"]}
            placeholder="Add a member"
            onChange={async (e) => {
              const team = project.team || [];
              team.push({ userId: e._id, userName: e.name, userAvatar: e.avatar, userEmail: e.email, userJobTitle: e.job_title, userRole: e.role });
              await api.put(`/project/${project._id}`, { team });
              toast.success("User assigned");
              fetchData();
            }}
          />
        </div>
        <CreateClient project={project} />
      </div>
      <div className="border border-gray-200 rounded-md p-4 mb-2">
        <div className="grid grid-cols-4 gap-6">
          {project.team
            .filter((member) => member.userRole !== "client")
            .sort((memberA, memberB) => memberB.hoursLastThreeMonths - memberA.hoursLastThreeMonths)
            .map((member) => (
              <UserCard key={member._id} member={member} project={project} />
            ))}
        </div>
      </div>
      <div className="flex items-center gap-2 mb-2 mt-5">
        <div className="font-semibold text-sm tracking-wider">Clients</div>
        <span className="bg-sky-50 border border-sky-100 text-sky-600 px-5 py-1 rounded-full">{members.filter((member) => member.role === "client")?.length}</span>
      </div>
      <div className="border border-gray-200 rounded-md p-4 mb-2">
        <div className="grid grid-cols-4 gap-2">
          {members
            .filter((member) => member.role === "client")
            .map((member) => (
              <div
                key={member._id}
                className="flex flex-col items-center gap-4 p-4 border border-gray-200 rounded-md cursor-pointer hover:border-gray-400 transition-all duration-150 "
                onClick={(e) => e.stopPropagation()}>
                <img src={member.avatar} alt="avatar" className="w-20 h-20 rounded-full" />
                <div>
                  <div className="text-lg font-semibold text-center">{member.name}</div>
                  <div className="text-sm text-gray-500 text-center">{member.email}</div>
                </div>
              </div>
            ))}
        </div>
      </div>
    </div>
  );
}




const CreateClient = ({ project }) => {
  const [open, setOpen] = useState(false);
  const [mode, setMode] = useState("email"); // 'email' or 'link'
  const [values, setValues] = useState({});
  const [link, setLink] = useState("");

  async function onSubmitEmail() {
    try {
      values.status = "active";
      values.role = "client";
      values.availability = "not available";
      const res = await api.post("/user", values);
      if (!res.ok) throw res;

      const team = project.team || [];
      team.push({
        userId: res.data._id,
        userName: res.data.name,
        userEmail: res.data.email,
        userRole: "client",
        userAvatar: res.data.avatar,
        userJobTitle: res.data.job_title,
      });
      await api.put(`/project/${project._id}`, { team });
      toast.success("Client invited via email");
      setOpen(false);
    } catch (e) {
      console.log(e);
      toast.error("Error inviting client", e.message);
    }
  }

  async function loadLink() {
    try {
      const linkResponse = await api.get(`/generate-link/${project._id}`);
      if (!linkResponse.ok) throw linkResponse;
      setLink(linkResponse.data.link); // Adjust according to your API response
      toast.success("Link loaded and ready to be copied");
    } catch (e) {
      console.log(e);
      toast.error("Error generating link", e.message);
    }
  }

  function copyLinkToClipboard() {
    if (link) {
      navigator.clipboard.writeText(link).then(
        () => {
          toast.success("Link copied to clipboard");
        },
        (err) => {
          toast.error("Failed to copy link", err.message);
        },
      );
    } else {
      toast.info("No link to copy. Load the link first.");
    }
  }

  return (
    <div>
      <button className="bg-sky-700 text-white py-2 px-4 rounded" onClick={() => setOpen(true)}>
        Manage Client Invitation
      </button>
      <Modal isOpen={open} onClose={() => setOpen(false)} className="min-w-96 p-6">
        <div className="flex justify-between">
          <button onClick={() => setMode("email")} className={`py-2 px-4 rounded ${mode === "email" ? "bg-blue-500 text-white" : "bg-gray-200"}`}>
            Invite by Email
          </button>
          <button onClick={() => setMode("link")} className={`py-2 px-4 rounded ${mode === "link" ? "bg-blue-500 text-white" : "bg-gray-200"}`}>
            Shareable Link
          </button>
        </div>
        {mode === "email" ? (
          <div className="flex flex-col mt-4">
            <input placeholder="Email" className="p-2 rounded border" value={values.email} onChange={(e) => setValues({ ...values, email: e.target.value })} />
            <button className="bg-blue-500 text-white mt-4 py-2 px-4 rounded" onClick={onSubmitEmail}>
              Send Invitation
            </button>
          </div>
        ) : (
          <div className="flex flex-col mt-4">
            <button className="bg-green-500 text-white py-2 px-4 rounded mb-2" onClick={loadLink}>
              Load Link
            </button>
            <button className="bg-blue-500 text-white py-2 px-4 rounded" onClick={copyLinkToClipboard}>
              Copy Link
            </button>
            {link && <p className="mt-2">Link: {link}</p>}
          </div>
        )}
      </Modal>
    </div>
  );
};





const UserCard = ({ member, project }) => {
  const navigate = useNavigate();
  const { search } = useLocation();
  const query = new URLSearchParams(search);
  const [user, setUser] = useState(member);

  const removeUser = async (userId) => {
    try {
      const team = project.team.filter((member) => member.userId !== userId);
      const { data } = await api.put(`/project/${project._id}`, { team });
      setProject(data);
      toast.success("User removed");
    } catch (e) {
      console.log(e);
    }
  };

  const getUserData = async () => {
    try {
      const { data } = await api.get(`/user/${member.userId}`);
      setUser(data);
    } catch (e) {
      console.log(e);
    }
  };

  useEffect(() => {
    if (!member) return;
    getUserData();
  }, [member]);

  return (
    <div
      key={user._id}
      className="relative group flex flex-col items-center rounded-md cursor-pointer hover:border-gray-400 transition-all duration-150 overflow-hidden shadow-md max-h-[500px]  bg-sky-800"
      onClick={(e) => {
        e.stopPropagation();
        query.set("user_modal_id", user._id);
        query.set("user_modal_tab", "skills");
        navigate({ search: query.toString() });
      }}>
      <button
        onClick={(e) => {
          e.stopPropagation();
          removeUser(member._id);
        }}
        className="hidden group-hover:block absolute top-1 right-1 rounded-full bg-neutral-50 border border-neutral-100 text-gray-700 p-2 hover:border-neutral-300">
        <IoMdClose />
      </button>
      <div className="flex absolute top-0 left-3 bg-[#00000055] backdrop-blur-sm rounded-b-lg">
        <div className="p-2 text-white text-xs">{user.tjms}€/d</div>
      </div>
      <img src={user.avatar} alt="avatar" className="object-cover flex-1 w-full max-h-[50%]" />
      <div className="flex-1 w-full">
        <div className="flex flex-col items-end w-full p-2">
          <div className="text-[9px] text-neutral-300 text-right uppercase tracking-wider font-light">{user.job_title}</div>
          <div className="text-base text-white text-right">{user.name}</div>
        </div>
        <div className="flex flex-col w-full p-2 text-white text-xs gap-2">
          <div className="flex justify-between items-center gap-2">
            <div className="font-bold">Location</div>
            <div className="text-right">{user?.office}</div>
          </div>
          <div className="flex justify-between items-center">
            <div className="font-bold">Exp</div>
            <div className="text-right">
              {user?.experience_total} {`year${user?.experience_total > 1 ? "s" : ""}`}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

const SubCategoryRadar = ({ userId, category }) => {
  const [data, setData] = useState();
  const [loading, setLoading] = useState();

  const getAnswers = async () => {
    try {
      setLoading(true);
      const resQuestions = await api.post("/skill_question/search", { limit: 1000 });
      const { data } = await api.post("/skill_answer/search", { userId });

      // for each answers, get the lowest score for each answer.skillCategory
      const subCategories = data.map((answer) => answer.skillSubCategory);
      const uniqueSubCategories = [...new Set(subCategories)];

      const scores = uniqueSubCategories.map((subcategory) => {
        const answers = data.filter((answer) => answer.skillSubCategory === subcategory);
        let levelValidated = 0;
        for (let i = 1; i <= 5; i++) {
          // if there is no question for this level, we consider the level validated
          const questionsForThisLevel = resQuestions.data.filter((question) => question.skillSubCategory === subcategory && question.level === i);
          if (!questionsForThisLevel.length) {
            levelValidated = i;
            continue;
          }

          // get all answers for this category and this level
          const answersForThisLevel = answers.filter((answer) => answer.levelValue === i);
          // if there is at least one falsy answer for this level, we consider the level not validated and we stop the loop
          if (!answersForThisLevel.length || answersForThisLevel.find((answer) => !parseInt(answer.value))) {
            break;
          }
          levelValidated = i;
        }
        return { category: answers[0].skillCategory, skill: subcategory, score: levelValidated };
      });
      setData(scores.filter((e) => e.category === category).filter((e) => e.score >= 3));
    } catch (error) {
      console.error("error", error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    getAnswers();
  }, [userId]);

  if (loading || !data) return <Loader />;

  return (
    <div className="h-full w-full flex justify-center">
      <div className="flex-1 flex flex-col items-center">
        <ResponsiveRadar
          data={data}
          keys={["score"]}
          indexBy="skill"
          valueFormat=">-.2f"
          margin={{ top: 30, right: 10, bottom: 30, left: 10 }}
          borderColor={{ from: "color" }}
          gridLabelOffset={15}
          dotSize={5}
          dotColor={{ theme: "background" }}
          dotBorderWidth={2}
          colors={{ scheme: "blues" }}
          blendMode="normal"
          motionConfig="wobbly"
          maxValue={5}
          gridLabel={(props) => {
            const anchor = props.x > 5 ? "start" : props.x < -5 ? "end" : "middle";
            return (
              <g transform={`translate(${props.x}, ${props.y})`}>
                <text fill="white" alignmentBaseline={"middle"} textAnchor={anchor} fontSize={10}>
                  {props.id}
                </text>
              </g>
            );
          }}
        />
      </div>
    </div>
  );
};
