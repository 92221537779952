import React from "react";
import { Routes, Route, useNavigate, useLocation } from "react-router-dom";

import Jobs from "./job_list";
import Channels from "./channel_list";

import Job from "./job_view";
import Channel from "./channel_view";

export default () => {
  const navigate = useNavigate();
  const location = useLocation();

  const is_active = (path) => location.pathname === path;

  return (
    <div>
      <div className="m-2">
        <div className="text-sm font-medium text-center text-gray-500 border-b border-gray-200 dark:text-gray-400 dark:border-gray-700 mx-3">
          <ul className="flex flex-wrap -mb-px list-none space-x-3">
            <li
              onClick={() => navigate(`/job`)}
              className={`inline-block p-2 rounded-t-lg border-b-2 border-transparent hover:text-gray-600 cursor-pointer dark:hover:text-gray-500 ${
                is_active("/job") ? "font-semibold text-black" : ""
              }`}>
              Jobs
            </li>
            <li
              onClick={() => navigate(`/job/channel`)}
              className={`inline-block p-2 rounded-t-lg border-b-2 border-transparent hover:text-gray-600 cursor-pointer dark:hover:text-gray-500 ${
                is_active("/job/channel") ? "font-semibold text-black" : ""
              }`}>
              Channels
            </li>
          </ul>
        </div>
        <Routes>
          <Route path="/" element={<Jobs />} />
          <Route path="/channel" element={<Channels />} />
          <Route path="/:id/*" element={<Job />} />
          <Route path="/channel/:id" element={<Channel />} />
        </Routes>
      </div>
    </div>
  );
};
