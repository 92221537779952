import React, { useEffect, useState } from "react";
import toast from "react-hot-toast";
import { useSelector } from "react-redux";
import moment from "moment";

import api from "../../../../services/api";
import Comments from "./Comments";
import DebounceInput from "../../../../components/DebounceInput";
import MultiSelect from "../../../../components/MultiSelect";
import SelectUser from "../../../../components/selectUser";

export default ({ epic, setEpic }) => {
  const user = useSelector((state) => state.Auth.user);
  // const [tickets, setTickets] = useState([]);

  // const fetchTickets = async () => {
  //   try {
  //     const { data } = await api.post(`/notion/search`, { epic_id: epic._id, project_id: epic.project_id });
  //     setTickets(data);
  //   } catch (error) {
  //     console.error(error);
  //   }
  // };

  // useEffect(() => {
  //   fetchTickets();
  // }, [epic]);

  return (
    <>
      <div className="flex mx-2 gap-2">
        <div className="w-1/3 pt-2">
          <DebounceInput
            debounceTimeout={600}
            className="input h-24"
            isTextArea={true}
            placeholder="Business Objective"
            type="text"
            id="business_objective"
            value={epic.business_objective || ""}
            onChange={async (e) => {
              const { data } = await api.put(`/epic/${epic._id}`, { business_objective: e.target.value });
              setEpic(data);
              toast.success("Business Objective updated!");
            }}
          />
          <SelectTags
            value={epic.tags}
            onChange={async (e) => {
              const { data } = await api.put(`/epic/${epic._id}`, { tags: e });
              setEpic(data);
              toast.success("Tags updated!");
            }}
            projectId={epic.project_id}
          />
          <SelectUser
            value={{ _id: epic.user_id, name: epic.user_name, avatar: epic.user_avatar }}
            onChange={async (e) => {
              const { data } = await api.put(`/epic/${epic._id}`, { user_id: e._id, user_name: e.name, user_avatar: e.avatar });
              setEpic(data);
              toast.success("User updated!");
            }}
          />
        </div>
        <div className="w-1/3">
          <div className="flex-1">
            <Comments
              value={epic.comments}
              onAdd={async (e) => {
                console.log("Adding comment", e);
                const comments = [...epic.comments, { text: e, user_name: user.name, user_id: user._id, user_avatar: user.avatar }];
                const { data } = await api.put(`/epic/${epic._id}`, { comments });
                setEpic(data);
                toast.success("Comment added!");
              }}
              onDelete={async (id) => {
                const comments = epic.comments.filter((c) => c._id !== id);
                const { data } = await api.put(`/epic/${epic._id}`, { comments });
                setEpic(data);
                toast.success("Comment deleted!");
              }}
            />
            <div className="mb-2">
              <span className="flex items-center text-xs text-gray-600 italic">
                Created by&nbsp;{epic.user_name} on {moment(epic.created_at).format("DD/MM/YYYY")}
              </span>
            </div>
          </div>
        </div>
        <div className="w-1/3">
          <div className="text-xs font-semibold mb-1">Jours </div>
          <div className="mb-2">
            <EstimationDays
              value={epic.days_required}
              onChange={async (e) => {
                const estimation = e * 500;

                const { data } = await api.put(`/epic/${epic._id}`, { days_required: e, estimation });
                setEpic(data);
                toast.success("Estimation updated!");
              }}
            />
          </div>
          <div className="text-xs font-semibold mb-1">Estimation (€) </div>
          <div className="mb-2">
            <EstimationPrice
              value={epic.estimation}
              onChange={async (e) => {
                const { data } = await api.put(`/epic/${epic._id}`, { estimation: e });
                setEpic(data);
                toast.success("Estimation updated!");
              }}
            />
          </div>
        </div>
      </div>
    </>
  );
};

const EstimationPrice = ({ value, onChange }) => {
  const [v, setV] = useState(value);

  useEffect(() => {
    setV(value);
  }, [value]);

  return <input className="input" disabled type="number" value={v} onChange={(e) => setV(e.target.value)} onBlur={() => onChange(v)} />;
};

const EstimationDays = ({ value, onChange }) => {
  const [v, setV] = useState(value);

  useEffect(() => {
    setV(value);
  }, [value]);

  return <input className="input" type="number" value={v} onChange={(e) => setV(e.target.value)} onBlur={() => onChange(v)} />;
};

const SelectTags = ({ value, onChange, projectId }) => {
  const [tags, setTags] = useState(value);
  const [options, setOptions] = useState([]);

  useEffect(() => {
    get();
  }, [value]);

  async function get() {
    const { data } = await api.post("/epic/aggregate", { project_id: projectId, key: "tags" });
    const arr = data.filter((e) => e._id !== null).map((e) => ({ label: e._id, value: e._id, count: e.count }));
    setOptions(arr);
  }

  return (
    <div className="flex flex-col gap-2">
      <MultiSelect
        values={tags.map((tag) => ({ value: tag, label: tag }))}
        id="select-tags"
        options={options}
        onSelectedChange={(e) => {
          setTags(e.map((e) => e.value));
          onChange(e.map((e) => e.value));
        }}
        placeholder="Tags"
        onCreateNew={(e) => {
          setTags([...tags, e.value]);
          get();
        }}
      />

      <div className="flex flex-wrap gap-1">
        {tags.map((tag) => (
          <span
            key={tag}
            className="text-xs w-fit  text-gray-600 bg-gray-100 px-2 py-1 rounded border border-gray-200 hover:bg-gray-200 cursor-pointer"
            onClick={() => {
              const newTags = tags.filter((t) => t !== tag);
              setTags(newTags);
              onChange(newTags);
            }}>
            {tag}
          </span>
        ))}
      </div>
    </div>
  );
};
