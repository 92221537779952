import React, { Fragment, useEffect, useState } from "react";
import toast from "react-hot-toast";
import validator from "validator";
import { Dialog, Transition } from "@headlessui/react";
import { FiExternalLink } from "react-icons/fi";
import { HiOutlineClock, HiOutlineDocumentText, HiOutlineMap, HiOutlineRss } from "react-icons/hi2";
import { BsArrowLeft, BsArrowRight } from "react-icons/bs";
import { FaUsers } from "react-icons/fa";
import { useDispatch } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import { FaGithub, FaLinkedin } from "react-icons/fa";
import { BsCheckCircle, BsCheckCircleFill } from "react-icons/bs";
import { MdDelete, MdUpload } from "react-icons/md";
import { readFileAsync } from "../../../utils";

import animaj from "../../../assets/jobOffers/animaj.png";
import apiengagement from "../../../assets/jobOffers/apiengagement.png";
import betagouv from "../../../assets/jobOffers/betagouv.png";
import champerche from "../../../assets/jobOffers/champerche.png";
import climby from "../../../assets/jobOffers/climby.png";
import jeveuxaider from "../../../assets/jobOffers/jeveuxaider.png";
import jobmaker from "../../../assets/jobOffers/jobmaker.png";
import meteory from "../../../assets/jobOffers/meteory.png";
import moneywalkie from "../../../assets/jobOffers/moneywalkie.png";
import monsuivipsy from "../../../assets/jobOffers/monsuivipsy.png";
import oz from "../../../assets/jobOffers/oz.png";
import snu from "../../../assets/jobOffers/snu.png";
import { default as LOGO_SELEGO, default as selego } from "../../../assets/selego_logo2.jpg";

import Loader from "../../../components/loader";
import LoadingButton from "../../../components/loadingButton";
import Modal from "../../../components/modal";

import { setUser } from "../../../redux/auth/actions";
import api from "../../../services/api";
import amplitude from "../../../services/amplitude";
import Hotjar from "../../../services/hotjar";

const Create = () => {
  const navigate = useNavigate();
  const { search } = useLocation();
  const query = new URLSearchParams(search);
  const [loading, setLoading] = useState(true);

  const [jobPost, setJobPost] = useState(null);
  const [job, setJob] = useState(null);

  const [jobPostId, setJobPostId] = useState(query.get("jobpost"));

  const [jobsPostAccounting, setJobsPostAccounting] = useState(null);
  const [jobs, setJobs] = useState(null);
  const [currentJobIndex, setCurrentJobIndex] = useState();

  const [thankyouOpen, setThankyouOpen] = useState(false);

  useEffect(() => {
    amplitude.logEvent("signup");
  }, []);

  useEffect(() => {
    if (!jobs) {
      getJobs();
    } else {
      getJob();
    }
  }, [jobPostId]);

  useEffect(() => {
    getJob();
  }, [jobs]);
  useEffect(() => {
    if (!query.get("jobpost") && jobsPostAccounting && jobsPostAccounting.length) {
      query.set("jobpost", jobsPostAccounting[0]._id);
      window.location.href = `/auth/signup?${query.toString()}`;
    }
  }, [jobsPostAccounting]);

  async function getJob() {
    if (!jobs) return;
    const { data: p } = await api.get(`/jobpost/${jobPostId}?incrementViews=true`);
    setJobPost(p);
    setJob(jobs.find((j) => j._id === p.job_id));
    setLoading(false);
  }

  async function getJobs() {
    //fetch jobsPost accounting
    const { data } = await api.post(`/jobpost/public-search`);
    setJobsPostAccounting(data.filter((jp) => jp.channel_name === "Accounting"));

    //fetch active jobs
    const { data: dataJobs } = await api.post(`/job/public-search`, { status: ["active"] });
    setJobs(dataJobs);

    ///fetch jobPost
    const { data: datajobPost } = await api.get(`/jobpost/${jobPostId}`);
    setCurrentJobIndex(dataJobs.findIndex((j) => j._id === datajobPost.job_id));
  }

  const getPreviousJobPostId = () => {
    const jobId = currentJobIndex === 0 ? jobs[jobs.length - 1]._id : jobs[currentJobIndex - 1]._id;
    setCurrentJobIndex(jobs.findIndex((j) => j._id === jobId));
    return jobsPostAccounting.find((jp) => jp.job_id === jobId)._id;
  };

  const getNextJobPostId = () => {
    const jobId = currentJobIndex === jobs.length - 1 ? jobs[0]._id : jobs[currentJobIndex + 1]._id;
    setCurrentJobIndex(jobs.findIndex((j) => j._id === jobId));
    return jobsPostAccounting.find((jp) => jp.job_id === jobId)._id;
  };

  if (loading) return <Loader />;

  if (!job)
    return (
      <div>
        <Apply forceOpen />
      </div>
    );

  return (
    <>
      <Hotjar />
      <div className="min-h-screen bg-Slate-100 px-2 md:!px-20 mb-4 py-6">
        <div className="bg-white rounded-xl shadow-sm px-10">
          <img className="object-contain p-1 rounded-md shadow-sm w-14 h-14 md:w-20 md:h-20 bg-light-white mb-6" src={LOGO_SELEGO} />
          <div className="flex flex-col gap-4 md:flex-row items-start justify-between">
            <div className="flex flex-col">
              <h2 className="text-slate-700 text-xs md:text-normal uppercase font-sm tracking-wide">SELEGO</h2>
              <h1 className="textlg md:text-2xl text-black font-semibold mt-1 mb-2">{job.title}</h1>
              <div className="flex gap-x-5 gap-y-2 flex-wrap">
                {job.contract_type ? (
                  <div className="flex items-center gap-1">
                    <HiOutlineDocumentText className="text-gray-500" />
                    <span className="text-gray-600 text-xs">{job.contract_type}</span>
                  </div>
                ) : null}
                {job.contract_duration ? (
                  <div className="flex items-center gap-1">
                    <HiOutlineClock className="text-gray-500" />
                    <span className="text-gray-600 text-xs">{job.contract_duration}</span>
                  </div>
                ) : null}
                {job.location ? (
                  <div className="flex items-center gap-1">
                    <HiOutlineMap className="text-gray-500" />
                    <span className="text-gray-600 text-xs">{job.location}</span>
                  </div>
                ) : null}
                {job.remote ? (
                  <div className="flex items-center gap-1">
                    <HiOutlineRss className="text-gray-500" />
                    <span className="text-gray-600 text-xs">{job.remote}</span>
                  </div>
                ) : null}
                {job.applicant_count ? (
                  <div className="flex items-center gap-1">
                    <FaUsers className="text-gray-500" />
                    <span className="text-gray-600 text-xs">{job.applicant_count} applicants</span>
                  </div>
                ) : null}
              </div>
              <div className="mt-4 group items-center text-blue-600 hover:text-blue-800">
                <a href="https://www.selego.co" target="_blank" rel="noreferrer" className="flex items-center gap-2  font-semibold hover:underline  transition-colors duration-200">
                  View our website
                  <FiExternalLink className="w-5 h-5   transition-colors duration-200" />
                </a>
              </div>
            </div>
            <Apply jobPost={jobPost} job={job} setThankyouOpen={setThankyouOpen} />
          </div>
          <div className="py-4 md:!py-16">
            <div dangerouslySetInnerHTML={{ __html: job.description }}></div>
          </div>
        </div>
        <div className="my-3" />
        <div className="w-full flex justify-center">
          <Apply jobPost={jobPost} job={job} setThankyouOpen={setThankyouOpen} />
        </div>
        <div className="pt-6 pb-4 w-full flex justify-center">
          {jobs?.length ? (
            <div className="flex items-center gap-4">
              <button
                onClick={() => {
                  navigate(`/auth/signup?jobpost=${getPreviousJobPostId()}`);
                  setJobPostId(getPreviousJobPostId());
                }}
                className="flex items-center justify-center h-9 w-9 rounded-lg bg-[#fff] border-[1px] border-gray-300 hover:bg-gray-50 cursor-pointer">
                <BsArrowLeft />
              </button>
              <div className="tracking-widest">
                {currentJobIndex + 1}/{jobs.length}
              </div>
              <button
                onClick={() => {
                  navigate(`/auth/signup?jobpost=${getNextJobPostId()}`);
                  setJobPostId(getNextJobPostId());
                }}
                className="flex items-center justify-center h-9 w-9 rounded-lg bg-white border-[1px] border-gray-300 hover:bg-gray-50 cursor-pointer">
                <BsArrowRight />
              </button>
            </div>
          ) : null}
        </div>
        <div className="my-3" />
        <div className="bg-blue-900 rounded-xl shadow-sm p-4">
          <div className="flex flex-row items-center gap-4">
            <a href="https://www.selego.co" target="_blank" rel="noreferrer" className="rounded-3xl overflow-hidden">
              <img className="cursor-pointer" src={selego} alt="" width={70} height={70} />
            </a>
            <div>
              <h2 className="mt-3  text-sm font-semibold text-white">Selego</h2>
              <p className=" mb-4 text-sm font-normal text-white">
                We help startups create their product. By joining us you will have access to top startups / scalups, a community of experts, and resources that can help accelerate
                your career.
              </p>
            </div>
          </div>
          <h2 className="mt-8 text-sm font-semibold text-white">Our startups portfolio</h2>

          <div className="flex gap-4 py-4">
            <a href="https://moneywalkie.com/" target="_blank" rel="noreferrer" className="rounded-xl overflow-hidden">
              <img className="cursor-pointer" src={moneywalkie} alt="" width={100} height={100} />
            </a>
            <a href="https://www.jobmaker.fr/" target="_blank" rel="noreferrer" className="rounded-xl overflow-hidden">
              <img className="cursor-pointer" src={jobmaker} alt="" width={100} height={100} />
            </a>

            <a href="https://www.champerche.fr/" target="_blank" rel="noreferrer" className="rounded-xl overflow-hidden">
              <img className="cursor-pointer" src={champerche} alt="" width={100} height={100} />
            </a>

            <a href="https://www.snu.gouv.fr/" target="_blank" rel="noreferrer" className="rounded-xl overflow-hidden">
              <img className="cursor-pointer" src={snu} alt="" width={100} height={100} />
            </a>
            <a href="https://www.beta.gouv.fr/" target="_blank" rel="noreferrer" className="rounded-xl overflow-hidden">
              <img className="cursor pointer" src={betagouv} alt="" width={100} height={100} />
            </a>
            <a href="https://meteory.eu/en" target="_blank" rel="noreferrer" className="rounded-xl overflow-hidden">
              <img className="cursor-pointer" src={meteory} alt="" width={100} height={100} />
            </a>
            <a href="https://www.animaj.com/#pocoyo" target="_blank" rel="noreferrer" className="rounded-xl overflow-hidden">
              <img className="cursor-pointer" src={animaj} alt="" width={100} height={100} />
            </a>
            <a href="https://api-engagement.beta.gouv.fr/" target="_blank" rel="noreferrer" className="rounded-xl overflow-hidden">
              <img className="cursor-pointer" src={apiengagement} alt="" width={100} height={100} />
            </a>
            <a href="https://www.climbyapp.fr/" target="_blank" rel="noreferrer" className="rounded-xl overflow-hidden">
              <img className="cursor-pointer" src={climby} alt="" width={100} height={100} />
            </a>
            <a href="https://www.jeveuxaider.gouv.fr/" target="_blank" rel="noreferrer" className="rounded-xl overflow-hidden">
              <img className="cursor-pointer" src={jeveuxaider} alt="" width={100} height={100} />
            </a>
            <a href="https://play.google.com/store/apps/details?id=com.monsuivipsy&hl=fr" target="_blank" rel="noreferrer" className="rounded-xl overflow-hidden">
              <img className="cursor-pointer" src={monsuivipsy} alt="" width={100} height={100} />
            </a>
            <a href="https://play.google.com/store/apps/details?id=com.addicto&hl=fr" target="_blank" rel="noreferrer" className="rounded-xl overflow-hidden">
              <img className="cursor-pointer" src={oz} alt="" width={100} height={100} />
            </a>
          </div>
          <div className="flex  justify-center my-2">
            <a
              className="appearance-none hover:no-underline hover:text-gray-900 bg-[#fff] hover:bg-gray-50 text-gray-800 tracking-wider px-5 py-2 rounded-[10px]"
              href={`mailto:office@selego.co?subject=Contact pour le ${jobPostId ? `jobpost[${jobPostId}]` : ""}`}>
              Contact us
            </a>
          </div>
        </div>
      </div>

      <Modal
        isOpen={thankyouOpen}
        className="w-[90%] md:w-[500px] flex justify-center items-center flex-col min-h-[200px]"
        onClose={() => {
          setThankyouOpen(false);
        }}>
        <div className="p-6 text-center">
          <h4 className="text-2xl font-semibold">Thank You for Your Application!</h4>
          <p className="text-gray-500">We appreciate your interest in joining our team and the time and effort you put into the application.</p>
        </div>
      </Modal>
    </>
  );
};

const Apply = ({ jobPost, job, forceOpen, setThankyouOpen }) => {
  const dispatch = useDispatch();

  const [loading, setLoading] = useState(false);
  const [open, setOpen] = useState(forceOpen);

  const [values, setValues] = useState({});
  const [answers, setAnswers] = useState([]);

  async function handleSubmit(e) {
    try {
      e.preventDefault();
      if (!validator.isEmail(values?.email)) return toast.error("Invalid email");
      setLoading(true);

      const { data, token, code, ok } = await api.post(`/user/applicant`, {
        ...values,
        job_application_qna: job?.questions.map((question, index) => ({
          question: question.text,
          answer: answers[index] || "",
        })),
        jobpost_id: jobPost?._id,
        job_id: job?._id,
      });
      setLoading(false);

      if (!ok) return toast.error("Wrong signup", code);

      api.setToken(token);
      dispatch(setUser(data));
      setValues({});
      setAnswers([]);
      setThankyouOpen(true);
      setOpen(false);
    } catch (e) {
      setLoading(false);
      if (e.code === "USER_ALREADY_REGISTERED") {
        return toast.error(
          <span>
            This email is already registered.
            <br />
            We&apos;ll contact you soon.
          </span>,
        );
      }
      toast.error(e.code);
    }
  }

  if (!open) {
    return (
      <div className="flex flex-col gap-2">
        <button onClick={() => setOpen((e) => !e)} className="bg-blue-600 hover:bg-blue-500 text-white tracking-wider px-5 py-3 text-lg font-bold rounded-[10px]">
          Apply Now
        </button>
      </div>
    );
  }

  return (
    <>
      <Transition appear show={open} as={Fragment}>
        <Dialog as="div" className="relative z-[60]" onClose={() => setOpen(forceOpen || false)}>
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0">
            <div className="fixed inset-0 bg-black/70" />
          </Transition.Child>

          <div className="fixed inset-0 overflow-y-auto">
            <div className="flex min-h-full items-center justify-center">
              <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0 scale-95"
                enterTo="opacity-100 scale-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100 scale-100"
                leaveTo="opacity-0 scale-95">
                <Dialog.Panel
                  as="div"
                  className={`w-full !no-scroll p-6 z-10 bg-white max-w-[800px] max-h-[90%] rounded-xl shadow-searchbar absolute left-1/2 top-1/2 transform -translate-x-1/2 -translate-y-1/2 overflow-y-auto`}>
                  <form className="flex flex-col overflow-auto" onSubmit={handleSubmit}>
                    <div className="mb-6">{job ? <h1 className="text-2xl text-black font-bold mb-1">You are applying to join Selego.</h1> : null}</div>
                    <div className="flex flex-col gap-4 mb-2">
                      <div className="flex flex-col">
                        <div className="text-gray-800 text-base mb-1">Full Name</div>
                        <input
                          required
                          type="name"
                          value={values?.name}
                          onChange={(e) => setValues({ ...values, name: e.target.value })}
                          className="border-[1px] border-gray-300 placeholder:text-gray-300 rounded-lg text-gray-800 px-3 py-2"
                          placeholder="John Doe"
                        />
                      </div>
                    </div>
                    <div className="flex flex-col gap-4 mb-4 border-b pb-5">
                      <div className="flex flex-col">
                        <div className="text-gray-800 text-base mb-1">
                          Email <span className="text-red-600">*</span>
                        </div>
                        <input
                          required
                          type="email"
                          value={values?.email}
                          onChange={(e) => setValues({ ...values, email: e.target.value })}
                          className="border-[1px] border-gray-300 placeholder:text-gray-300 rounded-lg text-gray-800 px-3 py-2"
                          placeholder="example@mail.com"
                        />
                      </div>
                    </div>

                    <div className="mb-6 ">
                      <div className="text-dull-black text-base	font-semibold mb-1 flex items-center gap-1">
                        <p>Linkedin</p>
                        <FaLinkedin className="text-blue-600 text-lg" />
                        <span className="text-red-600">*</span>
                      </div>
                      <input
                        className="bg-transparent outline-0	block w-full p-2.5 mb-1.5	rounded-lg text-sm font-medium pl-3 border border-gray-200"
                        type="text"
                        placeholder="Enter your Linkedin URL"
                        value={values?.linkedin}
                        onChange={(e) => setValues({ ...values, linkedin: withHttp(e.target.value) })}
                      />
                      <div className="text-dull-black text-base	font-semibold mb-1 flex items-center gap-1 mt-3">
                        <p>Github</p>
                        <FaGithub className="text-black text-lg" />
                      </div>
                      <input
                        className="bg-transparent outline-0	block w-full p-2.5 mb-1.5	rounded-lg text-sm font-medium pl-3 border border-gray-200"
                        type="text"
                        placeholder="Enter your Github URL"
                        value={values?.github}
                        onChange={(e) => setValues({ ...values, github: withHttp(e.target.value) })}
                      />
                      <>
                        <p className="text-dull-black text-base	font-semibold mb-1">
                          Resume
                        </p>
                        <div>
                          <label className="flex items-center justify-between space-x-2 w-72 p-2.5 mb-2 rounded-lg pl-3 border border-gray-200 cursor-pointer">
                            <div className=" flex items-center gap-2">
                              <MdUpload className="text-black text-lg" />
                              <span className="text-sm">Upload resume</span>
                            </div>
                            {values.resume ? <BsCheckCircleFill className="text-green-600 text-lg" /> : <BsCheckCircle className="text-gray-500 text-lg" />}
                            <input
                              type="file"
                              hidden
                              onChange={async (e) => {
                                const f = e.target.files[0];
                                const rawBody = await readFileAsync(f);
                                const { data } = await api.post(`/file`, { file: { rawBody, name: f.name }, folder: "/documents" });
                                setValues({ ...values, resume: data });
                              }}
                            />
                          </label>
                          {values.resume ? (
                            <div className="flex gap-5">
                              <a href={values.resume} className="text-sm underline mt-1">
                                {"Resume uploaded"}
                              </a>
                              <MdDelete className="text-black text-lg mt-1 cursor-pointer" onClick={() => setResume("")} />
                            </div>
                          ) : null}
                        </div>
                      </>

                      {job?.questions.length > 0 && (
                        <div className="mt-6 border-t pt-5">
                          {job?.questions.map((question, index) => (
                            <div key={index} className="mb-4">
                              <p className="text-dull-black text-base font-semibold mb-1">{question.text}</p>
                              <textarea
                                className="bg-transparent outline-0 block w-full p-2.5 mb-1.5 rounded-lg text-sm font-medium pl-3 border border-gray-200 min-h-20"
                                type="text"
                                placeholder="Type your answer"
                                value={answers[index] || ""}
                                onChange={(e) => {
                                  const newAnswers = [...answers];
                                  newAnswers[index] = e.target.value;
                                  setAnswers(newAnswers);
                                }}
                              />
                            </div>
                          ))}
                        </div>
                      )}

                      <div className="flex gap-3 mt-5 items-center">
                        <input
                          onChange={(e) => setValues({ ...values, profile_retention_consent: e.target.checked })}
                          type="checkbox"
                          name="profile_retention_consent"
                          id="keep_profile"
                        />
                        <label className="font-semibold text-sm" htmlFor="keep_profile">
                          Would you like us to keep your profile for upcoming job openings or potential projects?
                        </label>
                      </div>
                    </div>

                    <LoadingButton
                      loading={loading}
                      type="submit"
                      disabled={loading || !values?.email || !values?.linkedin}
                      className="mt-3 self-end bg-blue-600 hover:bg-blue-500 text-white tracking-wider px-5 py-3 text-base font-medium rounded-[10px] cursor-pointer">
                      Submit Application
                    </LoadingButton>
                  </form>
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </Dialog>
      </Transition>
    </>
  );
};

export default Create;

const withHttp = (url) => (!/^https?:\/\//i.test(url) ? `http://${url}` : url);
