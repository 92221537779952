import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Route, useLocation, BrowserRouter, Routes, Outlet, Navigate } from "react-router-dom";

import { setUser } from "./redux/auth/actions";
import Loader from "./components/loader";
import UserModal from "./components/userModal";
import NotionModal from "./components/notionModal";

import Auth from "./scenes/auth";

import PublicLean from "./scenes/learn_public";
import Reporting from "./scenes/reporting";
import PublicApplicant from "./scenes/public/applicant";
import Missions from "./scenes/missions";
import Job from "./scenes/job";
import Jobs from "./scenes/jobs";
import MissionsAo from "./scenes/missions_ao";
import Contact from "./scenes/contact";
import JobPooPublic from "./scenes/job_pool_public";
import EveningTalesSupport from "./scenes/eveningttales_support";
import MeilleurAcheteur from "./scenes/meilleur_acheteur";

import People from "./scenes/people";
import Documents from "./scenes/documents";
import Report from "./scenes/report";
import Cashout from "./scenes/cashout";
import Wallet from "./scenes/wallet";
import Portfolio from "./scenes/portfolio";
import Project from "./scenes/project";
import Bank from "./scenes/bank";
import Mail from "./scenes/mail";
import Activity from "./scenes/activity";
import Payment from "./scenes/payment";
import Salary from "./scenes/salary";
import Invoice from "./scenes/invoice";
import Quote from "./scenes/quote";
import Data from "./scenes/data";
import CalendarDaysOff from "./scenes/calendar_days_off";
import Dashboard from "./scenes/dashboard";
import Device from "./scenes/device";
import Birthdays from "./scenes/birthdays";
import Skills from "./scenes/skills";
import Learn from "./scenes/learn";
import LearnRating from "./scenes/learn_rating";
import LearnEditor from "./scenes/learn_editor";
import Pool from "./scenes/job_pool";
import SalesPipeline from "./scenes/sales_pipeline";
import Ticketing from "./scenes/ticketing";
import Meetings from "./scenes/meetings";
import Organisation from "./scenes/organisation";
import Organigramme from "./scenes/organigramme";
import Account from "./scenes/account";
import Referral from "./scenes/referral";
import JobPipeline from "./scenes/job_pipeline";
import Folk from "./scenes/folk";
import List from "./scenes/list";
import Acquiz from "./scenes/acquiz";
import Upwork from "./scenes/upwork";
import FindYourCofounder from "./scenes/find_your_cofounder";
import Channel from "./scenes/channel";
import Tools from "./scenes/tools";
import DebsQuiz from "./scenes/debsquiz";
import Debate from "./scenes/debate";
import Uxui from "./scenes/uxui";
import Review from "./scenes/review";
import Perks from "./scenes/perks";
import Articles from "./scenes/articles";
import Features from "./scenes/features";
import Questioning from "./scenes/questioning";

import Header from "./components/header";
import Questions from "./components/questions";

import api from "./services/api";

import Chat from "./chat";

import Home from "./scenes/home";

import SideBar from "./components/SideBar";
import ApplicantHome from "./scenes/home_applicant";
import useStore from "./services/store";
import helps from "./scenes/helps";

export default () => {
  return (
    <BrowserRouter>
      <Questions />
      <UserModal />
      <NotionModal />
      <Chat>
        <Routes>
          <Route path="/auth/*" element={<Auth />} />
          <Route element={<OpenLayout />}>
            <Route path="/reporting/:id" element={<Reporting />} />
            <Route path="/jobs" element={<Jobs />} />
            <Route path="/job_pool/*" element={<JobPooPublic />} />
            <Route path="/contact/*" element={<Contact />} />
            <Route path="/eveningtales_support/*" element={<EveningTalesSupport />} />
            <Route path="/applicant-public/:id" element={<PublicApplicant />} />
            <Route path="/public/applicant/:id" element={<PublicApplicant />} />
            <Route path="/meilleuracheteur" element={<MeilleurAcheteur />} />
            <Route element={<PublicLean />} path="/learn_public/*" />
          </Route>
          <Route element={<ProtectedLayout />}>
            <Route element={<RoleProtectedRoute allowedRoles={["admin", "normal", "client"]} element={Home} />} path="/" />
            <Route element={<RoleProtectedRoute allowedRoles={["admin", "normal", "applicant"]} element={ApplicantHome} />} path="/applicant" />
            <Route element={<RoleProtectedRoute allowedRoles={["admin", "normal", "client", "applicant"]} element={Ticketing} />} path="/message/*" />
            <Route element={<RoleProtectedRoute allowedRoles={["admin", "normal", "client", "applicant"]} element={Account} />} path="/account/*" />
            <Route element={<RoleProtectedRoute allowedRoles={["admin", "normal"]} element={Job} />} path="/job/*" />
            <Route element={<RoleProtectedRoute allowedRoles={["admin", "normal", "client"]} element={Perks} />} path="/perks/*" />
            <Route element={<RoleProtectedRoute allowedRoles={["admin", "normal", "client"]} element={Project} />} path="/project/*" />
            <Route element={<RoleProtectedRoute allowedRoles={["admin", "normal"]} element={Uxui} />} path="/uxui/*" />
            <Route element={<RoleProtectedRoute allowedRoles={["admin"]} element={Review} />} path="/review/*" />
            <Route element={<RoleProtectedRoute allowedRoles={["admin", "normal"]} element={Activity} />} path="/activity/*" />
            <Route element={<RoleProtectedRoute allowedRoles={["admin", "normal", "client"]} element={Data} />} path="/data/*" />
            <Route element={<RoleProtectedRoute allowedRoles={["admin", "normal"]} element={Referral} />} path="/referral/*" />
            <Route element={<RoleProtectedRoute allowedRoles={["admin", "normal", "client"]} element={Learn} />} path="/learn/*" />
            <Route element={<RoleProtectedRoute allowedRoles={["admin", "normal"]} element={LearnRating} />} path="/learn-rating/*" />
            <Route element={<RoleProtectedRoute allowedRoles={["admin", "normal", "client"]} element={Missions} />} path="/missions/*" />
            <Route element={<RoleProtectedRoute allowedRoles={["admin", "normal", "client"]} element={MissionsAo} />} path="/missions_ao/*" />
            <Route element={<RoleProtectedRoute allowedRoles={["admin", "normal"]} element={Meetings} F />} path="/meetings/*" />
            <Route element={<RoleProtectedRoute allowedRoles={["admin", "normal"]} element={Payment} />} path="/payment/*" />
            <Route element={<RoleProtectedRoute allowedRoles={["admin", "normal"]} element={Birthdays} />} path="/birthdays/*" />
            <Route element={<RoleProtectedRoute allowedRoles={["admin", "normal"]} element={CalendarDaysOff} />} path="/days-off/*" />
            <Route element={<RoleProtectedRoute allowedRoles={["admin", "normal"]} element={Debate} />} path="/debate/*" />

            <Route element={<RoleProtectedRoute allowedRoles={["admin", "normal"]} element={DebsQuiz} />} path="/debsquiz/*" />
            <Route element={<RoleProtectedRoute allowedRoles={["admin", "normal"]} element={Articles} />} path="/articles/*" />
            <Route element={<RoleProtectedRoute allowedRoles={["admin", "normal"]} element={Features} />} path="/features/*" />
            <Route element={<RoleProtectedRoute allowedRoles={["admin", "normal"]} element={Questioning} />} path="/questioning/*" />
            <Route element={<RoleProtectedRoute allowedRoles={["admin", "normal"]} element={Tools} />} path="/tools/*" />
            <Route element={<RoleProtectedRoute allowedRoles={["admin", "normal"]} element={Documents} />} path="/document/*" />
            <Route element={<RoleProtectedRoute allowedRoles={["admin", "normal"]} element={Report} />} path="/report/*" />
            <Route element={<RoleProtectedRoute allowedRoles={["admin", "normal"]} element={Cashout} />} path="/cashout/*" />
            <Route element={<RoleProtectedRoute allowedRoles={["admin", "normal"]} element={Wallet} />} path="/wallet/*" />
            <Route element={<RoleProtectedRoute allowedRoles={["admin", "normal"]} element={Portfolio} />} path="/portfolio/*" />
            <Route element={<RoleProtectedRoute allowedRoles={["admin", "normal"]} element={People} />} path="/people/*" />
            <Route element={<RoleProtectedRoute allowedRoles={["admin", "normal"]} element={Dashboard} />} path="/dashboard/*" />
            <Route element={<RoleProtectedRoute allowedRoles={["admin", "normal", "client"]} element={Pool} />} path="/pool/*" />
            <Route element={<RoleProtectedRoute allowedRoles={["admin", "normal", "client", "applicant"]} element={Salary} />} path="/salary/*" />
            <Route element={<RoleProtectedRoute allowedRoles={["admin", "normal"]} element={JobPipeline} />} path="/jobpipeline/*" />
            <Route element={<RoleProtectedRoute allowedRoles={["admin", "normal"]} element={Folk} />} path="/folk/*" />
            <Route element={<RoleProtectedRoute allowedRoles={["admin", "normal"]} element={List} />} path="/list/*" />
            <Route element={<RoleProtectedRoute allowedRoles={["admin", "normal"]} element={Acquiz} />} path="/acquiz/*" />
            <Route element={<RoleProtectedRoute allowedRoles={["admin", "normal"]} element={helps} />} path="/helps/*" />
            <Route element={<RoleProtectedRoute allowedRoles={["admin", "normal"]} element={SalesPipeline} />} path="/salespipeline/*" />
            <Route element={<RoleProtectedRoute allowedRoles={["admin", "normal"]} element={Upwork} />} path="/upwork/*" />
            <Route element={<RoleProtectedRoute allowedRoles={["admin", "normal"]} element={Invoice} />} path="/invoice/*" />
            <Route element={<RoleProtectedRoute allowedRoles={["admin", "normal"]} element={Quote} />} path="/quote/*" />
            <Route element={<RoleProtectedRoute allowedRoles={["admin", "normal"]} element={Skills} />} path="/skills/*" />
            <Route element={<RoleProtectedRoute allowedRoles={["admin", "normal"]} element={Bank} />} path="/bank/*" />
            <Route element={<RoleProtectedRoute allowedRoles={["admin", "normal"]} element={Mail} />} path="/mail/*" />
            <Route element={<RoleProtectedRoute allowedRoles={["admin", "normal"]} element={Organisation} />} path="/organisation/*" />
            <Route element={<RoleProtectedRoute allowedRoles={["admin", "normal"]} element={Organigramme} />} path="/organigramme/*" />
            <Route element={<RoleProtectedRoute allowedRoles={["admin", "normal"]} element={LearnEditor} />} path="/learn-editor/*" />
            <Route element={<RoleProtectedRoute allowedRoles={["admin", "normal"]} element={Device} />} path="/device/*" />
            <Route element={<RoleProtectedRoute allowedRoles={["admin", "normal"]} element={Channel} />} path="/channel/*" />
            <Route element={<RoleProtectedRoute allowedRoles={["admin", "normal"]} element={FindYourCofounder} />} path="/find_your_cofounder/*" />
          </Route>
        </Routes>
      </Chat>
    </BrowserRouter>
  );
};

const RoleProtectedRoute = ({ element: Element, allowedRoles, ...rest }) => {
  const user = useSelector((state) => state.Auth.user);
  if (!user) return <Navigate to="/auth" replace />;
  console.log(`Allowed roles: ${allowedRoles}`);
  console.log(`User role: ${user.role}`);
  if (!allowedRoles.includes(user.role)) return <div>Forbidden</div>;
  return <Element />;
};

const OpenLayout = () => {
  return (
    <div className="w-screen h-screen flex justify-center bg-white">
      <Outlet />
    </div>
  );
};

const ProtectedLayout = () => {
  const { sideBarOpen } = useStore();
  const dispatch = useDispatch();
  const location = useLocation();
  const [loading, setLoading] = useState(true);
  const user = useSelector((state) => state.Auth.user);
  const redirect = encodeURIComponent(window.location.href.replace(window.location.origin, ""));

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [location.pathname]);

  useEffect(() => {
    async function fetchData() {
      try {
        const res = await api.get("/user/signin_token");
        console.log("res1", res);
        if (!res.ok || !res.user) return setLoading(false);
        if (res.token) api.setToken(res.token);
        dispatch(setUser(res.user));
        // dispatch(setUser({ ...res.user, role: "client" }));
      } catch (e) {
        console.log(e);
      }
      setLoading(false);
    }

    fetchData();
  }, []);

  if (loading) return <Loader />;
  if (!user) return <Navigate to={`/auth?redirect=${redirect}`} replace />;

  return (
    <div className="w-screen h-screen relative bg-white">
      <SideBar />
      <div className={`${sideBarOpen ? "md:ml-64" : "ml-10"} flex flex-col ease-in-out duration-300 h-screen`}>
        <Header />
        <main className="flex-1 overflow-x-auto bg-[#FBFBF9]">
          <Outlet />
        </main>
      </div>
    </div>
  );
};
