import React, { useEffect, useState } from "react";
import { Routes, Route, useParams, Link } from "react-router-dom";
import { Chart as ChartJS, registerables } from "chart.js";
import { useSelector } from "react-redux";
import { IoIosLink } from "react-icons/io";
import { IoLogoGithub, IoIosStats, IoIosAt } from "react-icons/io";
import { RiRoadMapLine } from "react-icons/ri";

import Loader from "../../../components/loader";
import api from "../../../services/api";

import View from "./view";
import Edit from "./edit";
import Billing from "./billing";
import Activities from "./activities";
import Dashboard from "./dashboard";

import Budget from "./budget";
import Breakdown from "./breakdown";
import Invoice from "./invoice";
import Forecast from "./forecast";
import NewInvoice from "./newInvoice";
import Business from "./business";
import Document from "./document";
import Raw from "./raw";
import GitCommits from "./git-commits";
import Perks from "./perks";
import Team from "./team";
import TeamStats from "./team-stats";
import Reports from "./reports";
import Backlog from "./notion";
import Roadmap from "./roadmap";
import Meetings from "./meetings";
import Brief from "./brief";

ChartJS.register(...registerables);

const Project = () => {
  const [project, setProject] = useState(null);
  const [mode, setMode] = useState("client");
  const { id } = useParams();

  useEffect(() => {
    fetchData();
  }, [id]);

  async function fetchData() {
    const { data: u } = await api.get(`/project/${id}`);
    setProject(u);
  }

  const user = useSelector((state) => state.Auth.user);

  if (!project) return <Loader />;

  return (
    <div className="p-4">
      <div className="flex mb-2">
        <img
          src={project.logo || "https://www.gravatar.com/avatar/00000000000000000000000000000000?d=mp&f=y"}
          alt="projectlogo"
          className="rounded-md w-7 h-7 bg-gray-50 object-contain mr-4"
        />
        <h1 className="text-2xl font-bold">{project.name}</h1>
        <div className="flex-1 space-y-2"></div>
        <div className="flex flex-col justify-end h-full">
          <p className="text-sm text-right">Created the {new Date(project.created_at).toLocaleDateString()}</p>
          <div className="flex gap-4">
            <Links project={project} />
          </div>
        </div>
      </div>

      <div className="mb-2 flex items-center justify-between gap-2 mt-2">
        <nav className=" w-10/12 bg-white rounded-md flex item-center gap-1 border border-neutral-300 p-1 overflow-x-auto">
          {mode === "client" && <TabItem href="/" title="Dashboard" roles={["admin", "client", "normal"]} />}
          {mode === "client" && <TabItem href="/view" title="View" roles={["admin", "client", "normal"]} />}
          {mode === "admin" && <TabItem href="/budget" title="Budget" roles={["admin", "normal"]} />}

          {mode === "admin" && <TabItem href="/breakdown" title="Breakdown" roles={["admin", "normal"]} />}
          {mode === "client" && <TabItem href="/activities" title="Activities" roles={["admin", "client", "normal"]} />}
          {mode === "client" && <TabItem href="/backlog" title="Backlog" roles={["admin", "client", "normal"]} />}
          {mode === "client" && <TabItem href="/roadmap" title="Roadmap" roles={["admin", "client", "normal"]} />}
          {mode === "client" && <TabItem href="/invoice" title="Invoice" roles={["admin", "client", "normal"]} />}
          {mode === "admin" && <TabItem href="/business" title="Business" roles={["admin"]} />}

          {mode === "client" && <TabItem href="/document" title="Document" roles={["admin", "client", "normal"]} />}
          {mode === "client" && <TabItem href="/team" title="Team" roles={["admin", "client", "normal"]} />}
          {mode === "client" && <TabItem href="/reports" title="Reports" roles={["admin", "client", "normal"]} />}
          {mode === "client" && <TabItem href="/perks" title="Perks" roles={["admin", "client", "normal"]} />}
          {mode === "client" && <TabItem href="/brief" title="Brief" roles={["admin", "client", "normal"]} />}

          {mode === "admin" && <TabItem href="/edit" title="Edit" roles={["admin", "normal"]} />}
          {mode === "admin" && <TabItem href="/meetings" title="Meetings" roles={["admin"]} />}
          {mode === "admin" && <TabItem href="/raw" title="Raw" roles={["admin", "normal"]} />}
        </nav>
        {user.role === "admin" && (
          <div className="w-2/12 rounded-xl">
            <div className="flex border rounded-xl">
              <button className={`w-1/2 py-3 text-center rounded-xl ${mode === "client" ? "blue-btn" : "transparent-btn"}`} onClick={() => setMode("client")}>
                Client
              </button>
              <button className={`w-1/2 py-3 text-center rounded-xl ${mode === "admin" ? "blue-btn" : "transparent-btn"}`} onClick={() => setMode("admin")}>
                Admin
              </button>
            </div>
          </div>
        )}
      </div>
      <div>
        <Routes>
          <Route path="/" element={<Dashboard project={project} setProject={setProject} />} />
          <Route path="/view" element={<View project={project} setProject={setProject} />} />
          <Route path="/edit" element={<Edit project={project} setProject={setProject} />} />
          <Route path="/budget" element={<Budget project={project} setProject={setProject} />} />
          <Route path="/breakdown" element={<Breakdown project={project} setProject={setProject} />} />
          <Route path="/activities" element={<Activities project={project} setProject={setProject} />} />
          <Route path="/backlog/:tab?" element={<Backlog project={project} setProject={setProject} />} />
          <Route path="/roadmap" element={<Roadmap project={project} setProject={setProject} />} />
          <Route path="/invoice" element={<Invoice project={project} setProject={setProject} />} />
          <Route path="/billing" element={<Billing project={project} setProject={setProject} />} />
          <Route path="/forecast" element={<Forecast project={project} setProject={setProject} />} />
          <Route path="/new-invoice" element={<NewInvoice project={project} setProject={setProject} />} />
          <Route path="/business" element={<Business project={project} setProject={setProject} />} />
          <Route path="/document" element={<Document project={project} setProject={setProject} />} />
          <Route path="/reports" element={<Reports project={project} setProject={setProject} />} />
          <Route path="/git" element={<GitCommits project={project} setProject={setProject} />} />
          <Route path="/perks" element={<Perks project={project} setProject={setProject} />} />
          <Route path="/raw" element={<Raw project={project} setProject={setProject} />} />
          <Route path="/team" element={<Team project={project} setProject={setProject} />} />
          <Route path="/team-stats" element={<TeamStats project={project} setProject={setProject} />} />
          <Route path="/meetings" element={<Meetings project={project} setProject={setProject} />} />
          <Route path="/brief" element={<Brief project={project} setProject={setProject} />} />
        </Routes>
      </div>
    </div>
  );
};

const TabItem = ({ href, title, roles }) => {
  const { id } = useParams();

  const arr = window.location.pathname.split("/");
  const active = arr[arr.length - 1] === href.split("/")[1];

  const { user } = useSelector((state) => state.Auth);

  if (!roles.includes(user.role)) return <div />;

  return (
    <Link to={`/project/${id}${href}`} className={`${active ? "blue-btn" : "transparent-btn"} relative rounded flex items-center`}>
      <div className="flex items-center gap-2 truncate">{title}</div>
    </Link>
  );
};

// const Links = ({ project }) => {
//   const LINKS = [
//     { label: "Website", url: project.website, logo: <IoIosAt /> },
//     { label: "Metabase", url: project.metabase, logo: <IoIosStats /> },
//     { label: "Roadmap", url: project.roadmap, logo: <RiRoadMapLine /> },
//     { label: "Github", url: project.github, logo: <IoLogoGithub /> },
//   ]
//     .concat(project.links.map((project_link) => ({ label: project_link?.label, url: project_link.url, logo: <IoIosLink /> })))
//     .filter((link) => link.url);

//   return LINKS.map((link, i) => (
//     <a key={i} target="blank" href={link.url} className="text-sm hover:font-semibold flex items-center gap-1">
//       <div>{link.logo}</div>
//       <div>{link.label}</div>
//     </a>
//   ));
// };

const Links = ({ project }) => {
  const Link = ({ label, url, logo }) => (
    <a key={label} target="blank" href={url} className="text-sm hover:font-semibold flex items-center gap-1">
      <div>{logo}</div>
      <div>{label}</div>
    </a>
  );

  return (
    <React.Fragment>
      {project.website && <Link label="Website" url={project.website} logo={<IoIosAt />} />}
      {project.metabase && <Link label="Metabase" url={project.metabase} logo={<IoIosStats />} />}
      {project.roadmap && <Link label="Roadmap" url={project.roadmap} logo={<RiRoadMapLine />} />}
      {project.github && <Link label="Github" url={project.github} logo={<IoLogoGithub />} />}
      {project.links.map((project_link) => project_link.url && <Link key={project_link.label} url={project_link.url} label={project_link.label} logo={<IoIosLink />} />)}
    </React.Fragment>
  );
};

export default Project;
