import React, { useEffect, useState } from "react";

import api from "../services/api";

export default ({ value, userName, onChange, disabled = false, placeholder = "All users", name = new Date().getTime(), userRoles = ["admin", "normal"] }) => {
  const [users, setUsers] = useState([]);
  const [search, setSearch] = useState(userName ?? "");

  useEffect(() => {
    searchUser();
  }, [search]);

  useEffect(() => {
    if (!userName) return;
    setSearch(userName);
  }, [userName]);

  async function searchUser() {
    const res = await api.post("/user/search", { search, role: userRoles, per_page: 1000, sort: "name" });
    setUsers(res.data.users);
  }

  // test deploy


  return (
    <div>
      <div>
        <input
          list={name}
          value={search}
          disabled={disabled}
          className="input"
          placeholder={placeholder}
          onChange={(e) => {
            setSearch(e.target.value);
            if (e.target.value === "all") {
              return onChange();
            }
            const f = users.find((f) => e.target.value === f.name);
            if (f) onChange(f);
            if (!e.target.value) onChange({});
          }}
        />
        <datalist
          id={name}
          name={name}
          className="w-[180px] bg-[#FFFFFF] text-[12px] text-[#212325] font-semibold py-[4px] px-[4px] rounded-[5px] border-r-[16px] border-[transparent] cursor-pointer shadow-sm"
          value={value && value.name}
          defaultValue="">
          <option value="" disabled>
            Select user
          </option>
          {users
            .sort(function (a, b) {
              if (!a) return -1;
              if (!b) return 1;
              if ((a.name || "").toLowerCase() < (b.name || "").toLowerCase()) return -1;
              if ((a.name || "").toLowerCase() > (b.name || "").toLowerCase()) return 1;
              return 0;
            })
            .map((e) => (
              <option key={e._id} value={e.name} />
            ))}
        </datalist>
      </div>
    </div>
  );
};
