import React, { useEffect, useState } from "react";
import toast from "react-hot-toast";
import { FaLinkedin } from "react-icons/fa";

import moment from "moment";
import SelectUsers from "../selectUser";
import SelectS_Contact from "../selectS_Contact";
import TextEditor from "../TextEditor";

import api from "../../services/api";

export default ({ action, setAction, refetch = null }) => {
  async function handleSubmit(e) {
    e.preventDefault();
    try {
      const { ok, data } = await api.put(`/s_action/${action._id}`, action);
      if (!ok) return toast.error("Error updating action");
      toast.success("Action updated!");
      setAction(data);
    } catch (e) {
      console.log(e);
      toast.error("Error updating action!");
    }
  }

  async function onDelete(id) {
    if (confirm("Are you sure you want to delete this action?") === false) return;
    try {
      await api.remove(`/s_action/${id}`);
      toast.success("Action deleted!");
      if (refetch) refetch();
      setAction(null);
    } catch (e) {
      console.log(e);
      toast.error("Error deleting action!");
    }
  }

  function handleChange(e) {
    setAction({ ...action, [e?.target?.name]: e?.target?.value });
  }

  return (
    <form onSubmit={handleSubmit} className="flex flex-wrap justify-between gap-4 p-4 text-sm my-4 border shadow-sm">
      <div className="flex-1">
        <div className="p-2">
          <div className="mb-4">
            <label className="block text-gray-700 text-sm font-bold mb-2">Owner:</label>
            <SelectUsers userName={action.userName} onChange={(v) => setAction({ ...action, userId: v?._id, userName: v.name, userAvatar: v.avatar })} />
          </div>
          <div className="mb-4">
            <UserSelect label="Category" name="category" value={action.category} onChange={handleChange}>
              {!action.category && <option value="">Select</option>}
              <option value="CALL_BACK">Call Back</option>
              <option value="CONTACT_SOMEONE">Contact Someone</option>
              <option value="SEND_PROPOSAL">Send Proposal</option>
              <option value="OTHER">Other</option>
              <option value="SORTLIST">Sortlist</option>
              <option value="TRUSTPILOT">Trustpilot</option>
            </UserSelect>
          </div>
          <div>
            <DeadlineInput label="Deadline" value={action.deadline?.split("T")[0]} onChange={handleChange} action={action} setAction={setAction} />
          </div>
        </div>
      </div>
      <div className="flex-1 p-2">
        <ContactSelector action={action} setAction={setAction} />
      </div>
      <div className="w-full p-2">
        <TextEditor value={action?.description || ""} onChange={(e) => setAction({ ...action, description: e.target.value })} defaultPrompt={``} />
      </div>
      <div className="w-full p-2 flex justify-between">
        <button type="submit" className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded">
          Update Action
        </button>
        <button
          className="bg-red-500 hover:bg-red-700 text-white font-bold py-2 px-4 rounded"
          onClick={(e) => {
            e.preventDefault();
            onDelete(action._id);
          }}>
          Delete
        </button>
      </div>
    </form>
  );
};

const DeadlineInput = ({ value, onChange, label, className, action, setAction }) => {
  const [selectedType, setSelectedType] = useState("CUSTOM");
  const disabled = selectedType !== "CUSTOM";
  return (
    <div className={`${className} w-full`}>
      <div className="px-1 text-sm font-medium mb-2">{label}</div>
      <div className="flex gap-2">
        <button
          className={`rounded-md border-[1px] bg-[#ffffff] disabled:bg-gray-100 border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 md:text-sm py-2 px-2 ${
            selectedType === "NEXT_WEEK" ? "bg-blue-500 text-white" : ""
          }`}
          onClick={(e) => {
            e?.preventDefault();
            setSelectedType("NEXT_WEEK");
            setAction({ ...action, deadline: moment().add(1, "week").toISOString() });
          }}>
          Next week
        </button>
        <button
          className={`rounded-md border-[1px] bg-[#ffffff] disabled:bg-gray-100 border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 md:text-sm py-2 px-2 ${
            selectedType === "NEXT_MONTH" ? "bg-blue-500 text-white" : ""
          }`}
          onClick={(e) => {
            e?.preventDefault();
            setSelectedType("NEXT_MONTH");
            setAction({ ...action, deadline: moment().add(1, "month").toISOString() });
          }}>
          Next month
        </button>
        <button
          className={`rounded-md border-[1px] bg-[#ffffff] disabled:bg-gray-100 border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 md:text-sm py-2 px-2 ${
            selectedType === "CUSTOM" ? "bg-blue-500 text-white" : ""
          }`}
          onClick={(e) => {
            e?.preventDefault();
            setSelectedType("CUSTOM");
            setAction({ ...action, deadline: moment().toISOString() });
          }}>
          Custom
        </button>
      </div>
      <input
        className="w-full rounded-md border-[1px] bg-[#ffffff] disabled:bg-gray-100 border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 md:text-sm placeholder:text-gray-300 py-2 px-2 mt-2"
        name={"deadline"}
        value={value}
        onChange={onChange}
        type={"date"}
        disabled={disabled}
      />
    </div>
  );
};

const ContactSelector = ({ action, setAction }) => {
  const [loading, setLoading] = useState(true);
  const [contact, setContact] = useState(null);

  useEffect(() => {
    if (action.contact_id) {
      loadContact(action.contact_id);
    }
  }, [action.contact_id]);

  const loadContact = async (contact_id) => {
    setLoading(true);
    try {
      const { data } = await api.get(`/s_contact/${contact_id}`);
      setContact(data);
    } catch (error) {
      console.error("Failed to load contact", error);
    }
    setLoading(false);
  };

  return (
    <div className="bg-white rounded-lg shadow p-4 max-w-lg mx-auto">
      <div className="font-medium w-full flex gap-2 flex-col">
        <SelectS_Contact
          userName={action?.contact_name}
          value={action?.contact_id}
          onChange={(item) => {
            if (!item?._id) {
              setAction({ ...action, contact_name: "", contact_id: "" });
              setContact(null);
            } else {
              setAction({ ...action, contact_name: `${item?.first_name || item?.firstName} ${item?.last_name || item?.lastName}`, contact_id: item?._id });
            }
          }}
        />
        {loading && <div className="text-center text-lg">Loading...</div>}
        {!loading && contact && (
          <div className="flex flex-col items-center text-center mt-4">
            <img src={contact.picture} alt={`${contact.first_name} ${contact.last_name}`} className="w-24 h-24 rounded-full" />
            <div className="flex flex-row items-center">
              <h1 className="text-lg font-semibold ml-2">{`${contact.first_name} ${contact.last_name}`}</h1>
              <a href={contact.linkedin} target="_blank" rel="noopener noreferrer" className="mt-1">
                <FaLinkedin size={24} className="text-blue-600 hover:text-blue-800" />
              </a>
            </div>

            <h2 className="text-sm text-gray-600">{contact.job_title}</h2>
            <p className="text-xs text-gray-500">{contact.location}</p>

            <p className="text-xs mt-1 px-2 overflow-hidden">{(contact?.linkedin_raw?.description || "").substring(0, 200)}</p>
          </div>
        )}
      </div>
    </div>
  );
};
const UserSelect = ({ name, value, onChange, label, children }) => (
  <div className="w-full flex flex-col gap-2">
    <div className="font-medium">{label}</div>
    <select
      className="w-full rounded-md border-[1px] border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 md:text-sm placeholder:text-gray-300 py-2 px-2"
      type="select"
      name={name}
      value={value}
      onChange={onChange}>
      {children}
    </select>
  </div>
);
