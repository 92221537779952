import React, { useEffect, useState } from "react";
import { Pie } from "react-chartjs-2";
import api from "../../../services/api";

export default ({ job }) => {
  const [jobPosts, setJobPosts] = useState([]);
  const [totalViews, setTotalViews] = useState(0);
  const [applicantCount, setApplicantCount] = useState(0);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    if (job) fetchStats();
  }, [job]);

  async function fetchStats() {
    try {
      const { data: jobposts_data } = await api.post("/jobpost/search", { job_id: job._id });
      setJobPosts(jobposts_data);
      setTotalViews(jobposts_data.reduce((sum, post) => sum + post.views, 0));

      const { data: user_data } = await api.post("/user/search", {
        role: "applicant",
        job_ids: [job._id],
        // applicant_status: ["NEW", "SHORTLIST", "CONTACTED", "FINAL_LIST", "INTERVIEW", "HIRE", "TECHNICAL_TEST"],
      });
      setApplicantCount(user_data.total);
      setLoading(false);
    } catch (error) {
      console.error("Error fetching stats:", error);
    }
  }

  const applicantPercentage = totalViews ? ((applicantCount / totalViews) * 100).toFixed(2) : 0;

  const chartData = {
    labels: ["Views", "Applicants"],
    datasets: [
      {
        data: [totalViews, applicantCount],
        backgroundColor: ["#36A2EB", "#FF6384"],
        hoverBackgroundColor: ["#36A2EB", "#FF6384"],
      },
    ],
  };

  const jobPostViews = jobPosts
    .filter((post) => post.views > 0)
    .map((post) => ({
      title: post.channel_name,
      views: post.views,
    }));

  const jobPostChartData = {
    labels: jobPostViews.map((post) => post.title),
    datasets: [
      {
        data: jobPostViews.map((post) => post.views),
        backgroundColor: ["#FF9F40", "#FF6384", "#36A2EB", "#4BC0C0", "#9966FF", "#FFCD56"],
        hoverBackgroundColor: ["#FF9F40", "#FF6384", "#36A2EB", "#4BC0C0", "#9966FF", "#FFCD56"],
      },
    ],
  };

  if (loading) return <div className="p-3 text-sm">Loading...</div>;

  return (
    <div className="flex lg:flex-row flex-col">
      <div className="flex flex-col gap-4 p-2 mt-5 lg:w-1/2 w-full">
        <div className="bg-white h-fit border rounded-lg">
          <table>
            <thead className="border-b divide-x">
              <tr>
                <th className="text-sm font-semibold p-2">Views</th>
                <th className="text-sm font-semibold p-2">Applicants</th>
                <th className="text-sm font-semibold p-2">Applicant Percentage (%)</th>
              </tr>
            </thead>

            <tbody className="divide-x">
              <tr>
                <td className="p-2 font-semibold"> {totalViews}</td>
                <td className="p-2 font-semibold"> {applicantCount}</td>
                <td className="p-2 font-semibold"> {applicantPercentage} %</td>
              </tr>
            </tbody>
          </table>
        </div>

        <div className="w-96 -mt-14">
          <Pie
            data={chartData}
            options={{
              plugins: {
                legend: {
                  position: "right",
                  labels: {
                    font: {
                      size: 20,
                    },
                  },
                },
              },
            }}
          />
        </div>
      </div>

      <div className="flex flex-col gap-4 p-2 mt-5 lg:w-1/2 w-full">
        <div className="bg-white h-fit border rounded-lg p-4">
          <h2 className="text-lg font-semibold">Job Posts View Distribution</h2>
          <div className="w-full flex justify-center items-center">
            <div className="w-96">
              <Pie
                data={jobPostChartData}
                options={{
                  plugins: {
                    legend: {
                      position: "right",
                      labels: {
                        font: {
                          size: 20,
                        },
                      },
                    },
                  },
                }}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
