import React, { useEffect, useState } from "react";

import toast from "react-hot-toast";
import { useLocation, useNavigate } from "react-router-dom";
import { Tree, TreeNode } from "react-organizational-chart";
import { Switch } from "@headlessui/react";

import api from "../../services/api";

export default ({}) => {
  const [tree, setTree] = useState(null);
  const [showSwitches, setShowSwitches] = useState(false);
  const [showNoManager, setShowNoManager] = useState(false);
  const [showMonthlyFreelence, setShowMonthlyFreelence] = useState(true);
  const [showDailyFreelence, setShowDailyFreelence] = useState(false);
  const [showHiredApplicants, setShowHiredApplicants] = useState(true);

  const { search } = useLocation();
  const navigate = useNavigate();
  const query = new URLSearchParams(search);

  useEffect(() => {
    get();
  }, []);

  async function get() {
    try {
      let arr = [];
      const res = await api.post(`/user/search`, { role: ["admin", "normal"], contracts: ["VIE", "CDD", "CDI", "APPRENTICESHIP", "INTERNSHIP"], status: "active" });
      arr.push(...res.data.users);
      const d = new Date();
      d.setMonth(d.getMonth() - 2);
      const res1 = await api.post(`/user/search`, { contracts: ["FREELANCE"], payment_type: "MONTHLY", status: "active" });
      res1.data.users.forEach((e) => {
        if (!arr.map((e) => e._id).includes(e._id)) arr.push(e);
      });

      const res2 = await api.post(`/user/search`, { role: "applicant", statuses: ["HIRE"] });
      res2.data.users.forEach((e) => {
        if (!arr.map((e) => e._id).includes(e._id)) arr.push(e);
      });
      arr = arr.map((e) => ({ ...e, children: [] }));
      const items = arr.reduce((acc, e) => {
        acc[e._id] = { ...e, children: [] };
        return acc;
      }, {});
      arr = arr.reduce((acc, e) => {
        if (e.manager_id && items[e.manager_id]) {
          items[e.manager_id].children.push(items[e._id]);
        } else {
          acc.push(items[e._id]);
        }
        return acc;
      }, []);
      arr.sort((a, b) => b.children.length - a.children.length);
      setTree(arr);
    } catch (error) {
      console.error(error);
      toast.error("Error while fetching data");
    }
  }

  const DisplayTree = ({ tree, onClick }) => {
    let userTree = tree;
    userTree.children = userTree.children.filter((e) => {
      if (!showNoManager && !e.manager_id && !e.children.length) return false;
      if (!showMonthlyFreelence && e.contract === "FREELANCE" && e.payment_type === "MONTHLY") return false;
      if (!showDailyFreelence && e.contract === "FREELANCE" && e.payment_type === "DAILY") return false;
      if (!showHiredApplicants && e.role === "applicant" && e.status === "HIRE") return false;
      return true;
    });
    if (!tree.children.length) return <></>;

    return (
      <>
        {userTree.children.map((e) => (
          <TreeNode label={<UserCard user={e} onClick={onClick} />}>
            <DisplayTree tree={e} onClick={onClick} />
          </TreeNode>
        ))}
      </>
    );
  };

  if (!tree) return <div>Loading</div>;

  return (
    <div className="p-4 h-full">
      <div className="flex flex-col gap-2">
        <div className="relative flex items-center justify-between">
          <div className="text-lg font-semibold">Organigramme</div>
          <button onClick={() => setShowSwitches(!showSwitches)} className="blue-btn">
            {showSwitches ? "Hide" : "Show"} Filters
          </button>
        </div>
        {showSwitches && (
          <div className="absolute right-4 top-32 z-50 flex flex-col gap-2 bg-white rounded-lg border p-4 ">
            <StyledSwitch state={showNoManager} setState={setShowNoManager} title="Show No Manager" />
            <StyledSwitch state={showMonthlyFreelence} setState={setShowMonthlyFreelence} title="Show Monthly Freelance" />
            <StyledSwitch state={showDailyFreelence} setState={setShowDailyFreelence} title="Show Daily Freelance" />
            <StyledSwitch state={showHiredApplicants} setState={setShowHiredApplicants} title="Show Hired Applicants" />
          </div>
        )}

        <div className="flex justify-between max-w-full overflow-auto ">
          <Tree label={<div>Root</div>}>
            <DisplayTree
              tree={{ children: tree }}
              onClick={(i) => {
                query.set("user_modal_id", i._id);
                navigate({ search: query.toString() });
              }}
            />
          </Tree>
        </div>
      </div>
    </div>
  );
};

const UserCard = ({ user, onClick }) => {
  const [activities, setActivities] = useState([]);
  const [projects, setProjects] = useState([]);
  const [projectsleads, setProjectsLeads] = useState([]);

  useEffect(() => {
    getActivities();
    getProjects();
  }, []);

  async function getActivities() {
    const dateFrom = new Date();
    dateFrom.setMonth(dateFrom.getMonth() - 2);
    const res = await api.post(`/activity/search`, { userId: user._id, dateFrom });
    const arr = [];
    for (let i = 0; i < res.data.length; i++) {
      const a = res.data[i];
      const f = arr.findIndex((e) => e.projectId == a.projectId);
      if (f !== -1) continue;
      arr.push(a);
    }
    setActivities(arr);
  }

  async function getProjects() {
    const dateFrom = new Date();
    dateFrom.setMonth(dateFrom.getMonth() - 2);
    const res = await api.post(`/project/search`, { responsible_id: user._id, last_activity_at: { gt: dateFrom } });
    setProjects(res.data.projects);

    const res1 = await api.post(`/project/search`, { lead_id: user._id, last_activity_at: { gt: dateFrom } });
    setProjectsLeads(res1.data.projects);
  }

  const count = user.children.length ? `(${user.children.length})` : "";

  return (
    <div className="bg-white border-[1px] border-gray-200 rounded-md min-w-64" onClick={(e) => onClick(user)}>
      <div className="flex items-center justify-center p-2">
        <img src={user.avatar} className="w-10 h-10 rounded-full" />
        <div className="ml-4">
          <div className="text-sm font-semibold">{`${user.name} ${count}`}</div>
          <div className="text-xs text-gray-500">{user.job_title}</div>
          <div className="text-xs text-gray-500">{user.contract}</div>
          <div>
            <div>Responsible</div>
            <div className="flex-col">
              {projects.map((e) => {
                return <img src={e.logo} className="h-6 w-6 rounded-full bg-gray-50 ring-2 ring-white" />;
              })}
            </div>
          </div>
          <div>
            <div>Leads</div>
            <div className="flex-col">
              {projectsleads.map((e) => {
                return <img src={e.logo} className="h-6 w-6 rounded-full bg-gray-50 ring-2 ring-white" />;
              })}
            </div>
          </div>
          <div>
            <div>Contribute</div>
            <div className="flex-col">
              {activities.map((e) => {
                return <img src={e.projectLogo} className="h-6 w-6 rounded-full bg-gray-50 ring-2 ring-white" />;
              })}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

const StyledSwitch = ({ state, setState, title }) => (
  <div className="flex items-center gap-2 justify-end">
    <label className="text-sm font-semibold">{title}</label>
    <Switch
      checked={state}
      onChange={() => setState(!state)}
      className={`${
        state ? "bg-sky-900" : "bg-gray-300"
      } relative inline-flex h-5 w-9 cursor-pointer rounded-full border-2 border-transparent transition-colors duration-200 ease-in-out focus:outline-none`}>
      <span
        aria-hidden="true"
        className={`${
          state ? "translate-x-4" : "translate-x-0"
        } pointer-events-none inline-block h-4 w-4 transform rounded-full bg-white shadow-lg ring-0 transition duration-200 ease-in-out`}
      />
    </Switch>
  </div>
);
