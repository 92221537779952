import React, { useState } from "react";

import RichTextEditorSun from "../../../components/RichTextEditor";
import API from "../../../services/api";
import toast from "react-hot-toast";

function Brief({ project, setProject }) {
  const [value, setValue] = useState(project.brief);

  const onUpdate = async () => {
    try {
      const { ok, data } = await API.put(`/project/${project._id}`, { brief: value });
      if (!ok) return toast.error("Error Updating Brief");
      toast.success("Brief Updated");
      setProject(data);
    } catch (error) {
      console.error(error);
      toast.error("Error Updating Brief");
    }
  };

  return (
    <div>
      <RichTextEditorSun
        buttonList={[
          ["font", "fontSize", "formatBlock"],
          ["bold", "underline", "italic"],
          ["fontColor", "hiliteColor"],
          ["align", "list", "lineHeight"],
          ["outdent", "indent"],
          ["link", "image", "video"],
          ["removeFormat"],
        ]}
        values={value}
        options={{ minHeight: "200px", backGorundColor: "white" }}
        onChange={(e) => setValue(e)}
      />
      <button onClick={onUpdate} className="blue-btn p-6 w-full">
        Update Brief
      </button>
    </div>
  );
}

export default Brief;
