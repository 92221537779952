import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";

import Pagination from "../../../components/Paginator";
import MultiSelect from "../../../components/MultiSelect";
import Table from "../../../components/Table";
import GithubIcon from "../../../assets/github.svg";

import api from "../../../services/api";

export default ({ job }) => {
  const [sort, setSort] = useState({ created_at: -1 });
  const [filter, setFilter] = useState({
    search: "",
    page: 1,
    per_page: 50,
    applicant_status: ["NEW", "SHORTLIST", "CONTACTED", "FINAL_LIST", "INTERVIEW", "HIRE", "TECHNICAL_TEST"],
  });
  const [users, setUsers] = useState();
  const [total, setTotal] = useState(0);

  useEffect(() => {
    load();
  }, [filter, sort]);

  async function load() {
    const { data } = await api.post(`/user/search`, { ...filter, role: "applicant", job_ids: [job._id], sort });
    setUsers(data.users);
    setTotal(data.total);
  }

  const handleSort = (key) => {
    if (key === "socials") return;

    setSort((prevSort) => {
      const newSortOrder = prevSort[key] === 1 ? -1 : 1;
      return { [key]: newSortOrder };
    });
  };

  if (!users) return <div className="p-3 text-sm">Loading...</div>;

  return (
    <div className="p-1">
      <div className="flex gap-2 items-center mb-3 my-2 justify-between">
        <div className="flex gap-2">
          <input placeholder="Search..." className="!text-sm" value={filter.search} onChange={(e) => setFilter({ ...filter, search: e.target.value, page: 1 })} />
          <MultiSelect
            id="select-priority"
            values={filter.applicant_status.map((p) => ({ value: p, label: p }))}
            options={["NEW", "REJECT", "SHORTLIST", "CONTACTED", "FINAL_LIST", "INTERVIEW", "HIRE", "TECHNICAL_TEST"].map((p) => ({ value: p, label: p }))}
            onSelectedChange={(e) => setFilter((f) => ({ ...f, applicant_status: e.map((e) => e.value) }))}
            placeholder="Status"
          />
        </div>

        <div className="flex gap-2 items-center">
          <div className="font-semibold">{total} applicants</div>
          <Export users={users} />
        </div>
      </div>

      <Table
        header={[
          { title: "Name", key: "name" },
          { title: "Country", key: "country" },
          { title: "Channel", key: "channel_name" },
          { title: "Status", key: "applicant_status" },
          { title: "Socials", key: "socials" },
          { title: "Created at", key: "created_at" },
        ]}
        sort={sort}
        onSort={handleSort}
        total={total}>
        {users.map((user) => (
          <tr key={user._id} className="hover:bg-gray-200">
            <td className="px-3 py-2 whitespace-nowrap text-sm text-gray-500">
              <Link to={`/job/${job._id}?user_modal_id=${user._id}&user_modal_tab=applicant`}>{user.name}</Link>
            </td>
            <td className="px-3 py-2 whitespace-nowrap text-sm text-gray-500">{user.country}</td>
            <td className="px-3 py-2 whitespace-nowrap text-sm text-gray-500">{user.channel_name}</td>
            <td className="px-3 py-2 whitespace-nowrap text-sm text-gray-500">{user.applicant_status}</td>
            <td className="px-3 py-2 max-w-xs truncate">
              <div className="flex space-x-2">
                {user.linkedin && (
                  <a href={user.linkedin} target="_blank" rel="noopener noreferrer">
                    <img src="https://upload.wikimedia.org/wikipedia/commons/c/ca/LinkedIn_logo_initials.png" alt="LinkedIn Logo" className="w-6 h-6" />
                  </a>
                )}
                {user.email && (
                  <a href={`mailto:${user.email}`}>
                    <img src="https://upload.wikimedia.org/wikipedia/commons/4/4e/Gmail_Icon.png" alt="Email Logo" className="w-6 h-6" />
                  </a>
                )}
                {user.resume && (
                  <a href={user.resume} target="_blank" rel="noopener noreferrer">
                    <img src="https://upload.wikimedia.org/wikipedia/commons/6/6c/PDF_icon.svg" alt="Resume Logo" className="w-6 h-6" />
                  </a>
                )}
                {user.github && (
                  <a href={user.github} target="_blank" rel="noreferrer">
                    <img alt="github" src={GithubIcon} className="w-6 h-6" />
                  </a>
                )}
              </div>
            </td>
            <td className="px-3 py-2 whitespace-nowrap text-sm text-gray-500">{user?.created_at && user.created_at.slice(0, 10)}</td>
          </tr>
        ))}
      </Table>
      <div className="my-4">
        <Pagination page={filter.page} setPage={(page) => setFilter({ ...filter, page })} last={Math.ceil(total / filter.per_page)} />
      </div>
    </div>
  );
};

const Export = ({ users }) => {
  const onClick = async () => {
    let csvContent = "data:text/csv;charset=utf-8,";
    csvContent += `Index;Name;Linkedin;TJM;\n`;

    for (let i = 0; i < users.length; i++) {
      const e = users[i];
      const str = `${i};${e.name};${e.linkedin};${e.tjm};\n`;
      csvContent += str;
    }

    var encodedUri = encodeURI(csvContent);
    window.open(encodedUri);
  };

  return (
    <button className="btn btn-primary" onClick={onClick}>
      Export
    </button>
  );
};
