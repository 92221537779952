import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";

import Loader from "../../../components/loader";

import api from "../../../services/api";

import Info from "./info";
import Raw from "./raw";

const TABS = [
  { title: "Info", tab: "info" },
  { title: "Raw", tab: "raw" },
];

export default () => {
  const [tab, setTab] = useState("info");
  const [feature, setFeature] = useState();

  const { id } = useParams();

  useEffect(() => {
    get();
  }, [id]);

  async function get() {
    try {
      const { data } = await api.get(`/feature/${id}`);
      setFeature(data);
    } catch (e) {
      console.log("a", e);
    }
  }

  if (!feature) return <Loader />;

  return (
    <div className="p-4">
      <div className="mb-4 overflow-x-scroll">
        <TabBar tabs={TABS} setTab={setTab} active={tab} />
      </div>
      <div>
        {tab === "info" && <Info feature={feature} setFeature={setFeature} />}
        {tab === "raw" && <Raw feature={feature} />}
      </div>
    </div>
  );
};

const TabBar = ({ tabs, setTab, active }) => (
  <nav className="bg-white rounded-md flex item-center gap-2 w-full border border-neutral-300 overflow-hidden p-1">
    {tabs.map((tab, i) => (
      <TabItem key={i} tab={tab.tab} title={tab.title} setTab={setTab} active={active === tab.tab} />
    ))}
  </nav>
);

const TabItem = ({ tab, title, setTab, active }) => (
  <button onClick={() => setTab(tab)} className={`${active ? "blue-btn" : "transparent-btn"} relative rounded py-2 px-4 flex items-center gap-2`}>
    <div className="flex items-center gap-2">{title}</div>
  </button>
);
