import React, { useState } from "react";
import moment from "moment";
import ReactTooltip from "react-tooltip";
import RichTextEditorSun from "../RichTextEditor";

export default ({ value, onAdd, onDelete }) => {
  const [comment, setComment] = useState("");

  const handleSubmit = async (e) => {
    e.preventDefault();

    onAdd(comment);
    setComment("");
  };

  return (
    <div className="flex-grow-0 ">
      <form onSubmit={handleSubmit}>
        <div className="flex items-center justify-between">
          {/* <input type="text" className="w-[85%] projectsInput m-0" placeholder="Add a comment" value={comment} onChange={(e) => setComment(e.target.value)} /> */}
          <RichTextEditorSun
            buttonList={[
              ["font", "fontSize", "formatBlock"],
              ["bold", "underline", "italic"],
              ["fontColor", "hiliteColor"],
              ["align", "list", "lineHeight"],
              ["outdent", "indent"],
              [ "link", "image", "video"],
              ["removeFormat"],
            ]}
            values={comment}
            onChange={(e) => {
              setComment(e);
            }}
          />
          <button className="btn btn-primary ">Add</button>
        </div>
      </form>
      <div className="flex flex-col gap-y-1 mb-2 overflow-y-auto mt-2">
        {(value || [])
          .sort((a, b) => {
            return new Date(b.created_at) - new Date(a.created_at);
          })
          .map((comment, index) => (
            <div key={index} className="flex items-center bg-white p-2 rounded-md">
              <div>
                <div className="flex items-center gap-2 text-xs font-semibold">
                  <img src={comment.user_avatar} className="rounded-full w-6 h-6 mr-2" />
                  <div>{comment.user_name} </div>
                  <div className="font-normal text-gray-500" data-tip data-for={`comment-${comment._id}`}>
                    {moment(new Date(comment.created_at)).fromNow()}
                  </div>
                  <ReactTooltip id={`comment-${comment._id}`}>
                    <span>{new Date(comment.created_at).toLocaleString()}</span>
                  </ReactTooltip>
                </div>
                <div dangerouslySetInnerHTML={{ __html: comment.text }} />
              </div>
              <div className="flex-grow" />
              <div>
                {onDelete && (
                  <div
                    className="text-sm text-gray-500 ml-4 cursor-pointer underline"
                    onClick={() => {
                      if (!window.confirm("Are you sure?")) return;
                      onDelete(comment._id);
                    }}>
                    delete
                  </div>
                )}
              </div>
            </div>
          ))}
      </div>
    </div>
  );
};
