import { useEffect, useState } from "react";
import { useNavigate, Link } from "react-router-dom";
import Modal from "../../components/modal";
import toast from "react-hot-toast";

import api from "../../services/api";

export default () => {
  const [features, SetFeatures] = useState([]);

  useEffect(() => {
    getFeatures();
  }, []);

  const getFeatures = async () => {
    try {
      const { data } = await api.post("/feature/search");
      SetFeatures(data);
    } catch (e) {
      console.log(e);
      toast.error("Some Error!", e.code);
    }
  };

  return (
    <div className="py-8 px-10">
      <div className="flex items-center justify-between mb-4">
        <h1 className="text-2xl font-semibold">Features</h1>
        <Create />
      </div>
      <ul className="flex flex-col gap-1">
        <li className="w-full list-none flex flex-row gap-2 px-4">
          <h3 className="flex-1 text-gray-800 text-sm mb-0">Title</h3>
          <h3 className="flex-1 text-gray-800 text-sm mb-0">Created at</h3>
        </li>
        {features.map((e, i) => (
          <li key={i} className="list-none">
            <Link to={`/features/${e._id}`}>
              <div className="p-4 flex flex-col gap-2 md:flex-row items-center w-full rounded border border-gray-200 text-sm text-gray-700 hover:border-sky-700 appearance-none">
                <div className="flex-1 flex items-center gap-2">{e.title}</div>

                <div className="flex-1">{new Date(e.created_at).toLocaleDateString()}</div>
              </div>
            </Link>
          </li>
        ))}
      </ul>
    </div>
  );
};

const Create = () => {
  const [values, setValues] = useState({ title: "", description: "" });
  const [open, setOpen] = useState(false);
  const navigate = useNavigate();

  const handleSubmit = async () => {
    try {
      const res = await api.post("/feature", values);
      if (!res.ok) throw res;
      toast.success("Created!");
      setOpen(false);
      navigate(`/features/${res.data._id}`);
    } catch (e) {
      console.log(e);
      toast.error("Some Error!", e.code);
    }
  };

  return (
    <>
      <button className="btn blue-btn" onClick={() => setOpen(true)}>
        Create a new feature
      </button>
      <Modal isOpen={open} className="max-w-3xl w-full p-6" onClose={() => setOpen(false)}>
        <div className="p-8">
          <h2 className="text-2xl font-semibold mb-4">Create an Accounting feature</h2>
          <div className="grid grid-cols-2 gap-4 ">
            <div className="w-full col-span-2">
              <div className="text-sm font-medium mb-2">Title</div>
              <input className="projectsInput block" name="title" value={values.title} onChange={(e) => setValues({ ...values, title: e.target.value })} />
            </div>
          </div>
          <div className="flex justify-end mt-6">
            <button className="btn blue-btn w-32" onClick={handleSubmit}>
              Create
            </button>
          </div>
        </div>
      </Modal>
    </>
  );
};
