import React, { useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { toast } from "react-hot-toast";

import Modal from "../../../../../components/modal";
import api from "../../../../../services/api";

import RichTextEditorSun from "../../../../../components/RichTextEditor";

export default function ({ isOpen, onClose, projectId, setNotions }) {
  const [choice, setChoice] = useState("init");
  const [value, setValue] = useState({ project_id: projectId, description: "", multiTicket: false });

  const navigate = useNavigate();
  const { search } = useLocation();
  const query = new URLSearchParams(search);

  const handleCreate = async () => {
    try {
      toast.loading("Catégorisation du ticket...");
      // Changer le message au bout d'une seconde
      setTimeout(() => {
        toast.dismiss();
        toast.loading("Priorisation du ticket...");
      }, 1000);
      // Changer le message après 2 secondes
      setTimeout(() => {
        toast.dismiss();
        toast.loading("Traduction du titre...");
      }, 2000);
      const { data } = await api.post("/notion", value);
      setNotions((prev) => [...data, ...prev]);
      setValue({ project_id: projectId });
      onClose();
      if (!value.multiTicket) {
        query.set("task_modal_id", data[0]._id);
        navigate({ search: query.toString() });
      }
    } catch (error) {
      console.log(error);
    } finally {
      toast.dismiss();
    }
  };
  return (
    <Modal isOpen={isOpen} onClose={onClose}>
      {choice === "init" && <FormChoice setChoice={setChoice} onCreate={handleCreate} onChange={(e) => setChoice(e.target.value)} value={value} setValue={setValue} />}
    </Modal>
  );
}

const FormChoice = ({ value, setValue, onCreate }) => {
  return (
    <div className="flex flex-col gap-2 items-center justify-center max-w-lg mx-auto p-3">
      <h3 className="mb-1">Create new task</h3>
      <div className="mb-2">
        <input type="checkbox" id="myCheckbox" checked={value.multiTicket} onChange={(e) => setValue((prevValue) => ({ ...prevValue, multiTicket: !prevValue.multiTicket }))} />
        <label className="p-4">Generate several tickets</label>
      </div>

      <RichTextEditorSun
        buttonList={[
          ["font", "fontSize", "formatBlock"],
          ["bold", "underline", "italic"],
          ["fontColor", "hiliteColor"],
          ["align", "list", "lineHeight"],
          ["outdent", "indent"],
          ["link", "image", "video"],
          ["removeFormat"],
        ]}
        values={value.description}
        options={{ minHeight: "180px" }}
        onChange={(e) => setValue((prevValue) => ({ ...prevValue, description: e }))}
      />
      <div className="my-2" />
      <button disabled={!value.description} onClick={onCreate} className="blue-btn p-6 w-full">
        Create task
      </button>
    </div>
  );
};
