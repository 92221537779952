import React, { useEffect, useState, useRef } from "react";
import { motion, AnimatePresence } from "framer-motion";
import toast from "react-hot-toast";
import { useLocation, useNavigate } from "react-router-dom";

import RichTextEditorSun from "../../../../components/RichTextEditor";
import Table from "../../../../components/Table";
import Paginator from "../../../../components/Paginator";
import DebounceInput from "../../../../components/DebounceInput";
import MultiSelect from "../../../../components/MultiSelect";
import Modal from "../../../../components/modal";
import EpicModal from "./EpicModal";

import api from "../../../../services/api";

//https://share.streak.com/6DdASS6nUILCSbZP77UwZC

export default ({ project }) => {
  const [filters, setFilters] = useState({ page: 1, search: "" });
  const [viewMode, setViewMode] = useState("prioritization");

  return (
    <div className="roadmap-page-container">
      <div className="flex-1 flex justify-between items-center gap-2 py-2 mb-2">
        <DebounceInput
          debounce={300}
          className="input w-1/3"
          placeholder="Search..."
          value={filters.search}
          onChange={(e) => setFilters((prev) => ({ ...prev, search: e.target.value, page: 1 }))}
        />
        <MultiSelect
          id="select-status"
          options={["TODO", "IN_PROGRESS", "DONE"].map((status) => ({ value: status, label: status }))}
          onSelectedChange={(e) => setFilters((f) => ({ ...f, statuses: e.map((e) => e.value) }))}
          placeholder="Status"
        />
        <SelectTags value={filters.tags} onChange={(e) => setFilters((f) => ({ ...f, tags: e }))} project={project} />
        <MultiSelect
          id="select-urgency"
          options={["yes", "no"].map((priority) => ({ value: priority, label: priority }))}
          onSelectedChange={(e) => setFilters((f) => ({ ...f, urgent: e.map((e) => (e.value === "yes" ? true : false)) }))}
          placeholder="Priority"
        />

        <div className="w-2/12 rounded-xl">
          <div className="flex border rounded-xl">
            <button className={`w-1/2 py-3 text-center rounded-xl ${viewMode === "estimation" ? "blue-btn" : "transparent-btn"}`} onClick={() => setViewMode("estimation")}>
              Estimation
            </button>
            <button className={`w-1/2 py-3 text-center rounded-xl ${viewMode === "prioritization" ? "blue-btn" : "transparent-btn"}`} onClick={() => setViewMode("prioritization")}>
              Prioritization
            </button>
          </div>
        </div>
      </div>
      <div className="w-full">
        {viewMode === "estimation" && <EstimationTable filters={filters} project={project} />}
        {viewMode === "prioritization" && <PrioritizationTable filters={filters} project={project} />}
        {/* <Paginator filters={filters} setFilters={setFilters} /> */}
      </div>
      <EpicModal />
    </div>
  );
};

const PrioritizationTable = ({ filters, project }) => {
  const [epics, setEpics] = useState([]);
  const [selectedEpic, setSelectedEpic] = useState(null);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    fetchEpics();
  }, [filters]);

  const fetchEpics = async () => {
    setLoading(true);
    try {
      const { data, ok } = await api.post("/epic/search", { project_id: project._id, ...filters });
      if (!ok) return toast.error("Failed to fetch roadmaps");
      setEpics(addPointsAndSort(data));
    } catch (error) {
      console.error(error);
      toast.error("Failed to fetch roadmaps");
    } finally {
      setLoading(false);
    }
  };

  const handleSave = async (itemId, field, value) => {
    try {
      const { ok, data } = await api.put(`/epic/${itemId}`, { [field]: value });
      if (!ok) return toast.error("Failed to update");

      const index = epics.findIndex((item) => item._id === itemId);
      const newEpics = [...epics];
      newEpics[index] = { ...newEpics[index], [field]: value };
      setEpics(addPointsAndSort(newEpics));
      toast.success(`${field} updated successfully`);
    } catch (error) {
      console.error(error);
      toast.error("Failed to update");
    }
  };

  function addPointsAndSort(arr1) {
    const arr = [...arr1];
    for (let i = 0; i < arr.length; i++) {
      //= (IF(B2="XL",1,0) + IF(B2="L",2,0) + IF(B2="M",3,0) + IF(B2="S",4,0)  + IF(B2="XS",5,0))*(IF(C2="XL",5,0) + IF(C2="L",4,0) + IF(C2="M",3,0) + IF(C2="S",2,0)  + IF(C2="XS",1,0))  + (IF(D2="YES",100,0))

      let chargePoints = 0;
      if (arr[i].charge === "XL") chargePoints += 1;
      if (arr[i].charge === "L") chargePoints += 2;
      if (arr[i].charge === "M") chargePoints += 3;
      if (arr[i].charge === "S") chargePoints += 4;
      if (arr[i].charge === "XS") chargePoints += 5;

      let businessPoints = 0;
      if (arr[i].business_contribution === "XL") businessPoints += 5;
      if (arr[i].business_contribution === "L") businessPoints += 4;
      if (arr[i].business_contribution === "M") businessPoints += 3;
      if (arr[i].business_contribution === "S") businessPoints += 2;
      if (arr[i].business_contribution === "XS") businessPoints += 1;

      let basePoints = chargePoints * businessPoints;
      let urgentPoints = arr[i].urgent ? 100 : 0;
      arr[i].points = basePoints + urgentPoints;

      let days_required = 0;
      if (arr[i].charge === "XL") days_required += 20;
      if (arr[i].charge === "L") days_required += 15;
      if (arr[i].charge === "M") days_required += 10;
      if (arr[i].charge === "S") days_required += 5;
      if (arr[i].charge === "XS") days_required += 1;
      arr[i].days_required = days_required;
    }

    arr.sort((a, b) => b.points - a.points);
    return arr;
  }

  return (
    <div>
      <EpicCreationModal
        project={project}
        onCreate={(e) => {
          fetchEpics();
          setSelectedEpic(e);
        }}
      />
      <EpicModal
        epic={selectedEpic}
        onClose={() => {
          const index = epics.findIndex((item) => item._id === selectedEpic._id);
          const newEpics = [...epics];
          newEpics[index] = { ...newEpics[index], ...selectedEpic };
          setEpics(newEpics);
          setSelectedEpic(null);
        }}
      />
      <Table
        sort={{ points: -1 }}
        total={epics.length}
        header={[
          { title: "#", key: "index" },
          { title: "Tag", key: "tags" },
          { title: "Title", key: "title" },
          { title: "Business objective", key: "business_objective" },
          { title: "Charge", key: "charge" },
          { title: "Apport Business", key: "business_contribution" },
          { title: "Urgent", key: "urgent" },
          { title: "Points", key: "points" },
          { title: "Status", key: "status" },
        ]}
        loading={loading}
        height="h-[32rem]"
        noResultRender={<div className="w-full bg-white text-center p-2 my-5 rounded shadow-sm">No results found</div>}>
        <AnimatePresence>
          {epics.map((item, index) => {
            return (
              <motion.tr
                key={item._id}
                layout
                initial={{ opacity: 0, y: 20 }}
                animate={{ opacity: 1, y: 0 }}
                exit={{ opacity: 0, y: -20 }}
                transition={{ duration: 0.3 }}
                className={`${index % 2 === 0 ? "bg-gray-100" : "bg-gray-50"} cursor-pointer`}>
                <td onClick={() => setSelectedEpic(item)} className="border px-4 py-2 max-w-xs truncate">
                  {item.index}
                </td>
                <td onClick={() => setSelectedEpic(item)} className="border px-4 py-2 max-w-xs truncate hover:bg-gray-200">
                  {item.tags?.join(", ")}
                </td>
                <td onClick={() => setSelectedEpic(item)} className="border px-4 py-2 max-w-xs truncate hover:bg-gray-200">
                  {item.title}
                </td>
                <td onClick={() => setSelectedEpic(item)} className="border px-4 py-2 max-w-xs truncate hover:bg-gray-200">
                  {item.business_objective}
                </td>
                <EditableSelectCell itemId={item._id} field="charge" value={item.charge} options={["XS", "S", "M", "L", "XL"]} onSave={handleSave} />
                <EditableSelectCell itemId={item._id} field="business_contribution" value={item.business_contribution} options={["XS", "S", "M", "L", "XL"]} onSave={handleSave} />
                <EditableSelectCell itemId={item._id} field="urgent" value={item.urgent ? "yes" : "no"} options={["yes", "no"]} onSave={handleSave}>
                  <UrgentTag item={item} />
                </EditableSelectCell>
                <td className="border px-4 py-2 max-w-xs truncate">{item.points}</td>
                <EditableSelectCell itemId={item._id} field="status" value={item.status} options={["TODO", "IN_PROGRESS", "DONE"]} onSave={handleSave}>
                  <StatusTag item={item} />
                </EditableSelectCell>
              </motion.tr>
            );
          })}
        </AnimatePresence>
      </Table>
    </div>
  );
};

const SelectTags = ({ value, onChange, project }) => {
  const [options, setOptions] = useState([]);

  useEffect(() => {
    get();
  }, []);

  async function get() {
    const { data } = await api.post("/epic/aggregate", { project_id: project._id, key: "tags" });
    const arr = data.filter((e) => e._id !== null).map((e) => ({ label: e._id, value: e._id, count: e.count }));
    setOptions(arr);
  }

  return <MultiSelect id="select-status" options={options} value={value} onSelectedChange={(e) => onChange(e.map((e) => e.value))} placeholder="Tags" />;
};


const EstimationTable = ({ filters, project }) => {
  const [epics, setEpics] = useState([]);
  const [selectedEpic, setSelectedEpic] = useState(null);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    fetchEpics();
  }, [filters]);

  const fetchEpics = async () => {
    setLoading(true);
    try {
      const { data, ok } = await api.post("/epic/search", { project_id: project._id, ...filters });
      if (!ok) return toast.error("Failed to fetch roadmaps");
      // sort by created_at ( date value)
      data.sort((a, b) => new Date(a.created_at) - new Date(b.created_at));
      setEpics(data);
    } catch (error) {
      console.error(error);
      toast.error("Failed to fetch roadmaps");
    } finally {
      setLoading(false);
    }
  };

  const totalDays = epics.reduce((acc, item) => acc + item.days_required || 0, 0);
  const totalPrice = epics.reduce((acc, item) => acc + item.estimation, 0);

  return (
    <div>
      <EpicCreationModal
        project={project}
        onCreate={(e) => {
          fetchEpics();
          setSelectedEpic(e);
        }}
      />
      <EpicModal
        epic={selectedEpic}
        onClose={() => {
          console.log("CLOSE");
          const index = epics.findIndex((item) => item._id === selectedEpic._id);
          const newEpics = [...epics];
          newEpics[index] = { ...newEpics[index], ...selectedEpic };
          setEpics(newEpics);
          setSelectedEpic(null);
        }}
      />
      <Table
        sort={{ estimation: -1 }}
        total={epics.length}
        header={[
          { title: "#", key: "index" },
          { title: "Tags", key: "tags" },
          { title: "Title", key: "title" },
          { title: "Days Required", key: "days_required" },
          { title: "Estimation", key: "estimation" },
        ]}
        loading={loading}
        height="h-[32rem]"
        noResultRender={<div className="w-full bg-white text-center p-2 my-5 rounded shadow-sm">No results found</div>}>
        <AnimatePresence>
          {epics.map((item, index) => {
            return (
              <motion.tr
                key={item._id}
                layout
                initial={{ opacity: 0, y: 20 }}
                animate={{ opacity: 1, y: 0 }}
                exit={{ opacity: 0, y: -20 }}
                transition={{ duration: 0.3 }}
                className={`${index % 2 === 0 ? "bg-gray-100" : "bg-gray-50"} cursor-pointer`}>
                <td onClick={() => setSelectedEpic(item)} className="border px-4 py-2 max-w-xs truncate">
                  {item.index}
                </td>
                <td onClick={() => setSelectedEpic(item)} className="border px-4 py-2 max-w-xs truncate">
                  {item.tags?.join(", ")}
                </td>
                <td onClick={() => setSelectedEpic(item)} className="border px-4 py-2 max-w-xs truncate hover:bg-gray-200">
                  {item.title}
                </td>
                <td className="border px-4 py-2 max-w-xs truncate">{item.days_required}</td>
                <td onClick={() => setSelectedEpic(item)} className="border px-4 py-2 max-w-xs truncate hover:bg-gray-200">
                  {item.estimation ? `${formatNumberThreeDigits(item.estimation)} €` : ""}
                </td>
              </motion.tr>
            );
          })}
        </AnimatePresence>
        <tr className="bg-gray-200">
          <td className="border px-4 py-2 max-w-xs truncate"></td>
          <td className="border px-4 py-2 max-w-xs truncate"></td>
          <td className="border px-4 py-2 max-w-xs truncate">TOTAL</td>
          <td className="border px-4 py-2 max-w-xs truncate">{totalDays}</td>
          <td className="border px-4 py-2 max-w-xs truncate">{formatNumberThreeDigits(totalPrice)} €</td>
        </tr>
      </Table>
    </div>
  );
};

const formatNumberThreeDigits = (number) => {
  return number.toString().replace(/\B(?=(\d{3})+(?!\d))/g, " ");
};

const EpicCreationModal = ({ project, onCreate }) => {
  const [epicModal, setEpicModal] = useState(false);
  const [value, setValue] = useState("");

  const handleCreate = async () => {
    try {
      const title = value.replace(/<[^>]*>?/gm, "");
      const { ok, data } = await api.post("/epic", { project_id: project._id, title });
      if (!ok) return toast.error("Failed to create epic");
      toast.success("Epic created successfully");
      setEpicModal(false);
      onCreate(data);
      setValue("");
    } catch (error) {
      console.error(error);
      toast.error("Failed to create epic");
    }
  };

  return (
    <div>
      <div className="flex flex-row items-center gap-4">
        <button onClick={() => setEpicModal(true)} className="blue-btn">
          New Epic
        </button>
      </div>

      <Modal className="w-500px mx-auto px-6" isOpen={epicModal} onClose={() => setEpicModal(false)}>
        <form
          className="p-4 flex flex-col items-center "
          onSubmit={(e) => {
            e.preventDefault();
            handleCreate();
          }}>
          <div className="my-2">
            <label htmlFor="title" className="block">
              Description of the epic
            </label>
            {/* <textarea id="title" value={value} onChange={(e) => setValue(e.target.value)} className="input w-full" placeholder="Epic description" /> */}

            <RichTextEditorSun
              buttonList={[
                ["undo", "redo"],
                ["font", "fontSize", "formatBlock"],
                ["paragraphStyle", "blockquote"],
                ["bold", "underline", "italic", "strike", "subscript", "superscript"],
                ["fontColor", "hiliteColor"],
                ["align", "list", "lineHeight"],
                ["outdent", "indent"],
                ["table", "horizontalRule", "link", "image", "video"],
                ["removeFormat"],
              ]}
              values={value}
              options={{ minHeight: "180px" }}
              onChange={(e) => setValue(e)}
            />
          </div>
          <div className="flex justify-end gap-2 mt-2">
            <button type="submit" className="blue-btn">
              Create
            </button>
          </div>
        </form>
      </Modal>
    </div>
  );
};

const StatusTag = ({ item }) => {
  if (!item?.status) return null;
  const colors = {
    TODO: "bg-blue-600 text-xs text-white border-gray-700",
    IN_PROGRESS: "bg-blue-100 text-xs text-blue-800",
    DONE: "bg-green-100 text-xs text-green-800",
  };
  return <div className={`px-2 py-1 rounded w-fit ${colors[item?.status]}`}>{item?.status}</div>;
};

const UrgentTag = ({ item }) => {
  const colors = {
    true: "bg-red-600 text-xs text-white border border-red-700",
    false: "bg-green-100 text-xs text-green-800 border border-green-200",
  };
  return <div className={`px-2 py-1 rounded w-fit ${colors[item?.urgent]}`}>{item?.urgent ? "Yes" : "No"}</div>;
};

const EditableSelectCell = ({ itemId, field, value, options, onSave, children }) => {
  const [editing, setEditing] = useState(false);
  const [currentValue, setCurrentValue] = useState(value);
  const cellRef = useRef(null);

  const handleSave = async (e) => {
    const newValue = e.target.value;
    setCurrentValue(newValue);
    setEditing(false);
    await onSave(itemId, field, newValue);
  };

  const handleClickOutside = (event) => {
    if (cellRef.current && !cellRef.current.contains(event.target)) {
      setEditing(false);
    }
  };

  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  return (
    <td ref={cellRef} onClick={() => setEditing(true)} onBlur={() => setEditing(false)} className="border px-4 py-2 max-w-xs truncate hover:bg-gray-200">
      {editing ? (
        <select className="input p-1" value={currentValue} onChange={handleSave}>
          {options.map((option) => (
            <option key={option} value={option}>
              {option}
            </option>
          ))}
        </select>
      ) : (
        children || currentValue
      )}
    </td>
  );
};
